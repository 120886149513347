var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"flight-segment\">\n                    <h2>"
    + alias4(((helper = (helper = lookupProperty(helpers,"headline") || (depth0 != null ? lookupProperty(depth0,"headline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"headline","hash":{},"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":11,"column":36}}}) : helper)))
    + "</h2>\n\n                    <div class=\"row bordered-row\">\n                        <div class=\"col-sm-6\">\n                            <div>\n                                <span class=\"bold\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.overlay.travel-flight.airline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":16,"column":51},"end":{"line":16,"column":113}}})) != null ? stack1 : "")
    + ":</span> "
    + alias4(((helper = (helper = lookupProperty(helpers,"airline") || (depth0 != null ? lookupProperty(depth0,"airline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"airline","hash":{},"data":data,"loc":{"start":{"line":16,"column":122},"end":{"line":16,"column":133}}}) : helper)))
    + "\n                            </div>\n\n                            <div>\n                                <span class=\"bold\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.overlay.travel-flight.flightNo",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":20,"column":51},"end":{"line":20,"column":114}}})) != null ? stack1 : "")
    + ":</span> "
    + alias4(((helper = (helper = lookupProperty(helpers,"flightNo") || (depth0 != null ? lookupProperty(depth0,"flightNo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"flightNo","hash":{},"data":data,"loc":{"start":{"line":20,"column":123},"end":{"line":20,"column":135}}}) : helper)))
    + "\n                            </div>\n\n                            <div>\n                                <span class=\"bold\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.overlay.travel-flight.class",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":24,"column":51},"end":{"line":24,"column":111}}})) != null ? stack1 : "")
    + ":</span> "
    + alias4(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":24,"column":120},"end":{"line":24,"column":129}}}) : helper)))
    + "\n                            </div>\n\n                            <div class=\"flight-details\">\n                                <div class=\"flight-detail flight-detail-departure\">\n                                    <div class=\"bullet\"></div>\n\n                                    <span class=\"icon icon-icon-plane-starting\"></span>\n\n                                    <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"departureTime") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":34,"column":40},"end":{"line":38,"column":47}}})) != null ? stack1 : "")
    + "                                        "
    + alias4((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"departureDate") : depth0),"longDate",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":39,"column":40},"end":{"line":39,"column":79}}}))
    + "\n                                        <br>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"departureAirport") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":40},"end":{"line":43,"column":47}}})) != null ? stack1 : "")
    + "                                    </div>\n                                </div>\n                                <div class=\"flight-detail flight-detail-arrival\">\n                                    <div class=\"bullet\"></div>\n\n                                    <span class=\"icon icon-plane-landing\"></span>\n\n                                    <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"arrivalTime") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":52,"column":40},"end":{"line":56,"column":47}}})) != null ? stack1 : "")
    + "                                        "
    + alias4((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"arrivalDate") : depth0),"longDate",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":57,"column":40},"end":{"line":57,"column":77}}}))
    + "<br>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"arrivalAirport") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":40},"end":{"line":61,"column":47}}})) != null ? stack1 : "")
    + "                                    </div>\n                                </div>\n                            </div>\n\n                        </div>\n                        <div class=\"col-sm-6\">\n                            <div>\n                                <span class=\"bold\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.overlay.travel-flight.luggage",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":69,"column":51},"end":{"line":69,"column":113}}})) != null ? stack1 : "")
    + ":</span><br/>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"adultBagsText") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":32},"end":{"line":72,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"childBagsText") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":32},"end":{"line":75,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\n\n                        </div>\n                    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"closeButtonText") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":20},"end":{"line":85,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <span class=\"bold\">"
    + container.escapeExpression((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"departureTime") : depth0),"shortTime",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":35,"column":63},"end":{"line":35,"column":103}}}))
    + " "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.clock",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":35,"column":104},"end":{"line":35,"column":141}}})) != null ? stack1 : "")
    + " |</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <span class=\"bold\">– : – "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"general.clock",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":37,"column":69},"end":{"line":37,"column":106}}})) != null ? stack1 : "")
    + " |</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"departureAirport") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + " ("
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"departureAirport") : depth0)) != null ? lookupProperty(stack1,"iata") : stack1), depth0))
    + ")\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <span class=\"bold\">"
    + container.escapeExpression((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"arrivalTime") : depth0),"shortTime",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":53,"column":63},"end":{"line":53,"column":101}}}))
    + " "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.clock",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":53,"column":102},"end":{"line":53,"column":139}}})) != null ? stack1 : "")
    + " |</span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"arrivalAirport") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + " ("
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"arrivalAirport") : depth0)) != null ? lookupProperty(stack1,"iata") : stack1), depth0))
    + ")\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.overlay.travel-flight.adult",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":71,"column":41},"end":{"line":71,"column":101}}})) != null ? stack1 : "")
    + ": "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"adultBagsText") || (depth0 != null ? lookupProperty(depth0,"adultBagsText") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"adultBagsText","hash":{},"data":data,"loc":{"start":{"line":71,"column":103},"end":{"line":71,"column":120}}}) : helper)))
    + "</div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.overlay.travel-flight.child",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":74,"column":41},"end":{"line":74,"column":101}}})) != null ? stack1 : "")
    + ": "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"childBagsText") || (depth0 != null ? lookupProperty(depth0,"childBagsText") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"childBagsText","hash":{},"data":data,"loc":{"start":{"line":74,"column":103},"end":{"line":74,"column":120}}}) : helper)))
    + "</div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"overlay-close-wrapper-bottom\">\n                            <a class=\"button button-secondary js-close\"><span class=\"icon-arrow-left\"></span> "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"closeButtonText") || (depth0 != null ? lookupProperty(depth0,"closeButtonText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"closeButtonText","hash":{},"data":data,"loc":{"start":{"line":83,"column":110},"end":{"line":83,"column":129}}}) : helper)))
    + "</a>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"row\">\n    <div class=\"col-lg-8\">\n\n        <div class=\"overlay overlay-travel-flight\">\n\n            <a class=\"js-close overlay-close\"></a>\n            <h3 class=\"overlay-headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(alias1,"tpl.item.overlay.travel-flight.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":104}}})) != null ? stack1 : "")
    + "</h3>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"flightSegments") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":87,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});