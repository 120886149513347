import React from 'react';

import {
    createIntl as createIntlJs,
    createIntlCache,
    FormatNumberOptions,
    FormatDateOptions,
    IntlConfig,
    IntlShape
} from '@formatjs/intl';
import {createIntl as createIntlReact} from 'react-intl';

import messagesDE from '../i18n/messages.de.json';
import messagesEN from '../i18n/messages.en.json';
import Url from './util/url';


// Locale specific settings
// Redundant types for better control
export type ValidLocales = 'de' | 'en';
export type ValidLocalesAPI = ValidLocales;
let runtimeLocale: ValidLocales;

/**
 * Extracts the locale from the given string
 * @example extractLocale('de-DE') // 'de'
 * @example extractLocale('en-US') // 'en'
 * @example extractLocale('en') // 'en'
 * @example extractLocale('de') // 'de'
 * @example extractLocale('fr') // 'en'
 * @param locale
 * @returns
 */
export const extractLocale = (locale: string): ValidLocales => {
    if (locale.startsWith('de')) {
        return 'de';
    }

    return 'en';
};

/**
 * Sets the runtime locale
 * @param locale
 */
export const setRuntimeLocale = (locale: ValidLocales) => {
    runtimeLocale = locale;

    window.localStorage.setItem('appLocale', locale);
};

/**
 * Gets the locale from the runtime or the local storage
 * @returns
 */
export const getLocale = (): ValidLocales => {
    // If runtime locale is set, use it
    if (runtimeLocale) {
        return runtimeLocale;
    }

    // If locale is set in the query parameters, use it
    const url = new Url();
    const queryParameters = url.queryParameters as Record<string, string>;

    // Get locale from local storage
    const localLocale = window.localStorage.getItem('appLocale');

    // Extract locale from the given query parameters, localStorage or the local locale
    let targetLocale = extractLocale(queryParameters.locale || localLocale || window.navigator.language);

    // Get cookie with name enable_locales, if value is other than 'true' set locale to 'de'
    const cookie = document.cookie.split(';').find((cookie) => cookie.includes('enable_locales'));
    const enableLocales = cookie && cookie.split('=')[1] === 'true';
    if (!enableLocales) {
        targetLocale = 'de';
    }

    // Set the runtime locale
    setRuntimeLocale(targetLocale);

    return targetLocale;
};

/**
 * Gets the locale for the API
 * @returns
 */
export const getLocaleAPI = (): ValidLocalesAPI => {
    return getLocale() === 'de' ? 'de' : 'en';
};


// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const messagesMap: Record<ValidLocales, Record<string, string>> = {
    de: messagesDE,
    en: messagesEN
};

const getIntlShapeConfig = <T extends IntlConfig> (): T => {
    const locale = getLocale();

    return {
        locale: locale === 'de' ? 'de-DE' : 'en-GB',
        messages: messagesMap[locale]
    } as unknown as T;
};

const prepareTrustedHTML = (chunks: React.ReactNode): {
    __html: string
} => {
    let preparedChunks = chunks?.toString();
    if (Array.isArray(chunks)) {
        preparedChunks = chunks.join('');
    }

    return {
        __html: preparedChunks as string
    };
};

export const getIntlShapeReact = () => {
    return createIntlReact({
        ...getIntlShapeConfig(),
        defaultRichTextElements: {
            b: (chunks: React.ReactNode) => <b dangerouslySetInnerHTML={prepareTrustedHTML(chunks)}/>,
            i: (chunks: React.ReactNode) => <i dangerouslySetInnerHTML={prepareTrustedHTML(chunks)}/>,
            br: () => <br/>,
            s: (chunks: React.ReactNode) => <span dangerouslySetInnerHTML={prepareTrustedHTML(chunks)}/>,
            st: (chunks: React.ReactNode) => <strong dangerouslySetInnerHTML={prepareTrustedHTML(chunks)}/>,
            sb: (chunks: React.ReactNode) => <span className="bold"
                dangerouslySetInnerHTML={prepareTrustedHTML(chunks)}/>,
            sul: (chunks: React.ReactNode) => <span className="underline"
                dangerouslySetInnerHTML={prepareTrustedHTML(chunks)}/>,
            sup: (chunks: React.ReactNode) => <sup dangerouslySetInnerHTML={prepareTrustedHTML(chunks)}/>,
            snobr: (chunks: React.ReactNode) => <span className="nobr"
                dangerouslySetInnerHTML={prepareTrustedHTML(chunks)}/>,
            shdxsd: (chunks: React.ReactNode) => <span className="hidden-xs-down"
                dangerouslySetInnerHTML={prepareTrustedHTML(chunks)}/>,
            nbsp: () => '&nbsp;'
        }
    }, cache);
};

export const getIntlShapeJs = () => {
    return createIntlJs({
        ...getIntlShapeConfig(),
        defaultRichTextElements: {
            b: (chunks) => `<b>${chunks?.toString()}</b>`,
            i: (chunks) => `<i>${chunks?.toString()}</i>`,
            br: () => '<br />',
            s: (chunks) => `<span>${chunks?.toString()}</span>`,
            st: (chunks) => `<strong>${chunks?.toString()}</strong>`,
            sb: (chunks) => `<span class="bold">${chunks.toString()}</span>`,
            sul: (chunks) => `<span class="underline">${chunks?.toString()}</span>`,
            sup: (chunks) => `<sup>${chunks?.toString()}</sup>`,
            snobr: (chunks) => `<span class="nobr">${chunks?.toString()}</span>`,
            shdxsd: (chunks) => `<span class="hidden-xs-down">${chunks?.toString()}</span>`,
            nbsp: () => '&nbsp;'
        }
    }, cache);
};

// "Singleton"
let intl: IntlShape;
export const getIntl = () => {
    if (!intl) {
        intl = getIntlShapeJs();
    }

    return intl;
};

// Shortcuts
export const formatPrice = (price: number, options: FormatNumberOptions = {}) => getIntl().formatNumber(price, {
    currency: 'EUR',
    currencyDisplay: 'symbol',
    style: 'currency',
    minimumFractionDigits: 2,
    ...options
});

/*
germanShortDate: 'DD.MM.YYYY',
germanMediumDate: 'ddd, DD.MM.YYYY',
germanDateWithDay: 'dddd, DD.MM.YYYY',
germanShortTime: 'HH:mm',
*/
const validFormats = [
    'shortDate',
    'mediumDate',
    'shortTime',
    'longDate'
];

export const formatDate = (
    date: Date,
    format: 'shortDate' | 'mediumDate' | 'shortTime' | 'longDate' = 'shortDate',
    options: FormatDateOptions = {}
): string => {
    if (!validFormats.includes(format)) {
        throw new Error(`Invalid formatDate-format: ${format}`);
    }

    return getIntl().formatDate(date, {
        ...(format === 'shortDate'
            ? {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            }
            : {}),
        ...(format === 'mediumDate'
            ? {
                weekday: 'short',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            }
            : {}),
        ...(format === 'shortTime'
            ? {
                hour: '2-digit',
                minute: '2-digit'
            }
            : {}),
        ...(format === 'longDate'
            ? {
                weekday: 'long',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            }
            : {}),
        ...options
    });
};
