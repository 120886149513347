/* eslint-disable no-unreachable */
import _ from 'underscore';
import BaseModel from '../../../base/model/base';
import fecha from '../../../util/fecha-german';

import contentApiConfig from '../../../config/content-api';

/**
 * https://api.tuicruises.com/pcl-test/trips?tripCodes=MSE2413SEE
 *
 * Keys in use:
 * - shipImage
 * - numberOfNights
 * - customvar6
 * - ship
 * - itinerary
 * - dateFrom
 * - transreise
 * - dateTo
 * - routeMapURL
 * - tripName
 * - region
 * - routeImage
 * - startDate
 * - travelDates
 *
 * Not in use:
 *
 */
export default class TripInfoModel extends BaseModel {
    prepareUrlAndFetch (tripCode) {
        // this.url = contentApiConfig.tripInfoURL.replace('{tripCode}', tripCode);
        this.url = contentApiConfig.i18nUrlTripInfo(tripCode);

        return this.fetch();
    }


    parse (response) {
        if (Object.keys(response).length === 0) {
            return {};
        }
        const [trip] = response;

        if (response.dateFrom || response.dateTo) {
            response.travelDates = {};
            if (response.dateFrom) {
                response.travelDates.from = fecha.parse(response.dateFrom, 'YYYY-MM-DD');
                response.travelDates.daysToStart = this.dateDiffinDays(new Date(), new Date(response.travelDates.from));
            }

            if (response.dateTo) {
                response.travelDates.to = fecha.parse(response.dateTo, 'YYYY-MM-DD');
            }
        }

        if (response.itinerary) {
            _.each(response.itinerary, (item) => {
                if (item.date) {
                    item.displayShortdate = fecha.format(fecha.parse(item.date, 'YYYY-MM-DD'), 'DD.MM.');
                }
            });
        }

        return {
            shipImage: {
                rawImage: trip.ship.imageUrl,
                stageImageURL: `${trip.ship.imageUrl}?w=2880&h=1000`,
                smartphoneImageURL: `${trip.ship.imageUrl}?w=750&h=421`,
                desktopImageURL: `${trip.ship.imageUrl}?w=1920&h=1080`
            },
            numberOfNights: this.dateDiffinDays(new Date(trip.startDate), new Date(trip.endDate)),
            customvar6: trip.customvar6,
            ship: trip.ship.name,
            itinerary: trip.stages.map((stage) => ({
                date: stage.date,
                departureTime: stage.departureTime,
                arrivalTime: stage.arrivalTime,
                tenderPort: false,
                countryName: null,
                portName: stage.name,
                displayShortdate: fecha.format(fecha.parse(stage.date, 'YYYY-MM-DD'), 'DD.MM.')
            })),
            dateFrom: trip.startDate,
            transreise: trip.transreise,
            dateTo: trip.endDate,
            routeMapURL: trip.route ? trip.route.mapSquare : null,
            tripName: trip.name,
            region: trip.regions.map((v) => ({
                regionCode: v.code,
                regionName: v.name
            })),
            routeImage: {
                rawImage: trip.imageUrl,
                stageImageURL: `${trip.imageUrl}?w=2880&h=1000`,
                smartphoneImageURL: `${trip.imageUrl}?w=750&h=421`,
                desktopImageURL: `${trip.imageUrl}?w=1920&h=1080`
            },
            startDate: trip.startDate,
            travelDates: {
                from: trip.startDate,
                daysToStart: this.dateDiffinDays(new Date(), new Date(trip.startDate)),
                to: trip.endDate
            }
        };
    }

    hasTender () {
        let result = false;
        _.each(this.get('itinerary'), (item) => {
            if (item.tenderPort === true) {
                result = true;
            }
        });
        return result;
    }

    dateDiffinDays (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / (24 * 3600 * 1000));
    }
}

/*
@TODO: Remove this later
NEW:
[
    {
        "code": "MSE2413SEE",
        "name": "11 Nächte - Gran Canaria bis Mallorca",
        "startDateTime": "2024-04-07T22:30:00.000+01:00",
        "endDateTime": "2024-04-18T04:00:00.000+01:00",
        "ship": {
            "code": "MSE",
            "name": "Mein Schiff 1",
            "numberOfRestaurants": 12,
            "numberOfBars": 17,
            "outerDeckArea": 20500,
            "spaAndFitnessArea": 2400,
            "landingPage": "https://test.meinschiff.com/mein-schiff-1",
            "imageUrl": "https://images.ctfassets.net/nl4z0p8934li/26fZRcm2wkwdXbwPQgSko5/11b683f2c1c4f5fe59f3377f51c40bc2/MS1ms10118_tuicV3.jpg"
        },
        "excursionBookableDaysBeforeCruiseStart": 120,
        "imageUrl": "https://downloads.ctfassets.net/nl4z0p8934li/routeGroupImage413/8d17e2cd225c00c25a1416b99a6d4902/RegionsOpener_KAN_K3_sRGB.jpg",
        "stages": [
            {
                "name": "Las Palmas (Gran Canaria)",
                "portCode": "1089",
                "imageUrl": "https://images.ctfassets.net/nl4z0p8934li/PortImage10002309/70ff1d3737660c5eb128ee91fbc00281/hafenbild-las-palmas--gran-canaria-.jpg",
                "date": "2024-04-07",
                "arrivalTime": "04:30",
                "departureTime": "22:30",
                "arrivalDateTime": "2024-04-07T04:30:00.000+01:00",
                "departureDateTime": "2024-04-07T22:30:00.000+01:00"
            },
            {
                "name": "Santa Cruz (Teneriffa)",
                "portCode": "3089",
                "imageUrl": "https://images.ctfassets.net/nl4z0p8934li/PortImage10000279/62d8a499878b032cb5215e5c668c1733/hafenbild-santa-cruz--teneriffa-.jpg",
                "date": "2024-04-08",
                "arrivalTime": "08:00",
                "departureTime": "19:00",
                "arrivalDateTime": "2024-04-08T08:00:00.000Z",
                "departureDateTime": "2024-04-08T19:00:00.000Z"
            },
            {
                "name": "Santa Cruz (La Palma)",
                "portCode": "100003448",
                "imageUrl": "https://images.ctfassets.net/nl4z0p8934li/PortImage10002313/1ea2dda6e14e146d5560199d394c2f69/hafenbild-santa-cruz--la-palma-.jpg",
                "date": "2024-04-09",
                "arrivalTime": "08:00",
                "departureTime": "19:00",
                "arrivalDateTime": "2024-04-09T08:00:00.000Z",
                "departureDateTime": "2024-04-09T19:00:00.000Z"
            },
            {
                "name": "Funchal (Madeira)",
                "portCode": "634",
                "imageUrl": "https://images.ctfassets.net/nl4z0p8934li/PortImage10000280/22a410f5c00e5105ae4ac7abc30ebe94/hafenbild-funchal--madeira-.jpg",
                "date": "2024-04-10",
                "arrivalTime": "19:00",
                "departureTime": null,
                "arrivalDateTime": "2024-04-10T19:00:00.000Z",
                "departureDateTime": null
            },
            {
                "name": "Funchal (Madeira)",
                "portCode": "634",
                "imageUrl": "https://images.ctfassets.net/nl4z0p8934li/PortImage10000280/22a410f5c00e5105ae4ac7abc30ebe94/hafenbild-funchal--madeira-.jpg",
                "date": "2024-04-11",
                "arrivalTime": null,
                "departureTime": "18:00",
                "arrivalDateTime": null,
                "departureDateTime": "2024-04-11T18:00:00.000Z"
            },
            {
                "name": "Seetag",
                "portCode": null,
                "imageUrl": null,
                "date": "2024-04-12",
                "arrivalTime": null,
                "departureTime": null,
                "arrivalDateTime": null,
                "departureDateTime": null
            },
            {
                "name": "Cádiz",
                "portCode": "300",
                "imageUrl": "https://images.ctfassets.net/nl4z0p8934li/PortImage10002286/c13b2312d0df3d1160b7bff2b6a0de85/hafenbild-c-diz.jpg",
                "date": "2024-04-13",
                "arrivalTime": "08:00",
                "departureTime": "19:00",
                "arrivalDateTime": "2024-04-13T08:00:00.000+01:00",
                "departureDateTime": "2024-04-13T19:00:00.000+01:00"
            },
            {
                "name": "Tanger",
                "portCode": "2186",
                "imageUrl": "https://images.ctfassets.net/nl4z0p8934li/PortImage10002376/15cf78d011750179fc099529f8f4ad10/hafenbild-tanger.jpg",
                "date": "2024-04-14",
                "arrivalTime": "07:00",
                "departureTime": "20:00",
                "arrivalDateTime": "2024-04-14T07:00:00.000Z",
                "departureDateTime": "2024-04-14T20:00:00.000Z"
            },
            {
                "name": "Málaga",
                "portCode": "1186",
                "imageUrl": "https://images.ctfassets.net/nl4z0p8934li/PortImage10002203/e777737311af28cab685946287b0e363/hafenbild-m-laga.jpg",
                "date": "2024-04-15",
                "arrivalTime": "08:00",
                "departureTime": "19:00",
                "arrivalDateTime": "2024-04-15T08:00:00.000+01:00",
                "departureDateTime": "2024-04-15T19:00:00.000+01:00"
            },
            {
                "name": "Seetag",
                "portCode": null,
                "imageUrl": null,
                "date": "2024-04-16",
                "arrivalTime": null,
                "departureTime": null,
                "arrivalDateTime": null,
                "departureDateTime": null
            },
            {
                "name": "Valencia",
                "portCode": "2327",
                "imageUrl": "https://images.ctfassets.net/nl4z0p8934li/PortImage10000106/93eb3bbeb3328fed7961beaf53a76144/hafenbild-valencia.jpg",
                "date": "2024-04-17",
                "arrivalTime": "07:00",
                "departureTime": "18:00",
                "arrivalDateTime": "2024-04-17T07:00:00.000+01:00",
                "departureDateTime": "2024-04-17T18:00:00.000+01:00"
            },
            {
                "name": "Palma (Mallorca)",
                "portCode": "1511",
                "imageUrl": "https://images.ctfassets.net/nl4z0p8934li/PortImage10000057/09a5ca96df9ce9f5227d947c92f68580/hafenbild-palma--mallorca-.jpg",
                "date": "2024-04-18",
                "arrivalTime": "04:00",
                "departureTime": "22:00",
                "arrivalDateTime": "2024-04-18T04:00:00.000+01:00",
                "departureDateTime": "2024-04-18T22:00:00.000+01:00"
            }
        ],
        "regions": [
            {
                "code": "KN",
                "name": "Kanaren"
            },
            {
                "code": "MM",
                "name": "Südeuropa & Mittelmeer"
            }
        ],
        "route": {
            "mapSquare": "https://images.ctfassets.net/nl4z0p8934li/routeMap_KN_TKM_0016/0118b528d388f2723ba66bae82ac1b24/Gran_Canaria_bis_Mallorca_2024_RGBWebsite_Quadrat_V2.svg",
            "mapLandscape": null,
            "code": "KN_TKM_0016"
        }
    }
]
OLD:
{
    "shipImage": {
        "stageImageURL": "https://test.meinschiff.com/media/4906/97277737332096/ms10118_tuic.jpg",
        "smartphoneImageURL": "https://test.meinschiff.com/media/4906/52479225299336/ms10118_tuicV3.jpg",
        "desktopImageURL": "https://test.meinschiff.com/media/4906/36826451701429/ms10118_tuicV3.jpg"
    },
    "numberOfNights": 11,
    "customvar6": "TRANSREISEN",
    "ship": "Mein Schiff 1",
    "itinerary": [
        {
            "date": "2024-04-07",
            "departureTime": "22:30",
            "arrivalTime": null,
            "tenderPort": false,
            "countryName": "Spanien",
            "portName": "Las Palmas (Gran Canaria)",
            "displayShortdate": "07.04."
        },
        {
            "date": "2024-04-08",
            "departureTime": "19:00",
            "arrivalTime": "08:00",
            "tenderPort": false,
            "countryName": "Spanien",
            "portName": "Santa Cruz (Teneriffa)",
            "displayShortdate": "08.04."
        },
        {
            "date": "2024-04-09",
            "departureTime": "19:00",
            "arrivalTime": "08:00",
            "tenderPort": false,
            "countryName": "Spanien",
            "portName": "Santa Cruz (La Palma)",
            "displayShortdate": "09.04."
        },
        {
            "date": "2024-04-10",
            "departureTime": null,
            "arrivalTime": null,
            "tenderPort": false,
            "countryName": null,
            "portName": "Seetag",
            "displayShortdate": "10.04."
        },
        {
            "date": "2024-04-10",
            "departureTime": null,
            "arrivalTime": "19:00",
            "tenderPort": false,
            "countryName": "Portugal",
            "portName": "Funchal (Madeira)",
            "displayShortdate": "10.04."
        },
        {
            "date": "2024-04-11",
            "departureTime": "18:00",
            "arrivalTime": null,
            "tenderPort": false,
            "countryName": "Portugal",
            "portName": "Funchal (Madeira)",
            "displayShortdate": "11.04."
        },
        {
            "date": "2024-04-12",
            "departureTime": null,
            "arrivalTime": null,
            "tenderPort": false,
            "countryName": null,
            "portName": "Seetag",
            "displayShortdate": "12.04."
        },
        {
            "date": "2024-04-13",
            "departureTime": "18:00",
            "arrivalTime": "08:00",
            "tenderPort": false,
            "countryName": "Spanien",
            "portName": "Cádiz",
            "displayShortdate": "13.04."
        },
        {
            "date": "2024-04-14",
            "departureTime": "20:00",
            "arrivalTime": "07:00",
            "tenderPort": false,
            "countryName": "Marokko",
            "portName": "Tanger",
            "displayShortdate": "14.04."
        },
        {
            "date": "2024-04-15",
            "departureTime": "19:00",
            "arrivalTime": "08:00",
            "tenderPort": false,
            "countryName": "Spanien",
            "portName": "Málaga",
            "displayShortdate": "15.04."
        },
        {
            "date": "2024-04-16",
            "departureTime": null,
            "arrivalTime": null,
            "tenderPort": false,
            "countryName": null,
            "portName": "Seetag",
            "displayShortdate": "16.04."
        },
        {
            "date": "2024-04-17",
            "departureTime": "18:00",
            "arrivalTime": "07:00",
            "tenderPort": false,
            "countryName": "Spanien",
            "portName": "Valencia",
            "displayShortdate": "17.04."
        },
        {
            "date": "2024-04-18",
            "departureTime": null,
            "arrivalTime": "04:00",
            "tenderPort": false,
            "countryName": "Spanien",
            "portName": "Palma (Mallorca)",
            "displayShortdate": "18.04."
        }
    ],
    "dateFrom": "2024-04-07",
    "transreise": true,
    "dateTo": "2024-04-18",
    "routeMapURL": "https://test.meinschiff.com/media/1150/68282531725214/gran_canaria_bis_mallorca_2024_rgbwebsite_16-9_V2.svg",
    "tripName": "11 Nächte - Gran Canaria bis Mallorca",
    "region": [
        {
            "regionCode": "KN",
            "regionName": "Kanaren"
        },
        {
            "regionCode": "MM",
            "regionName": "Südeuropa & Mittelmeer"
        }
    ],
    "routeImage": {
        "stageImageURL": "https://test.meinschiff.com/media/6151/61083848610290/regionsopener_kan_k3_srgb.jpg",
        "smartphoneImageURL": "https://test.meinschiff.com/media/6151/67671571638897/regionsopener_kan_k3_srgbV3.jpg",
        "desktopImageURL": "https://test.meinschiff.com/media/6151/42060188740864/regionsopener_kan_k3_srgbV3.jpg"
    },
    "startDate": "2024-04-07T22:30:00+01:00",
    "travelDates": {
        "from": "2024-04-06T22:00:00.000Z",
        "daysToStart": 51,
        "to": "2024-04-17T22:00:00.000Z"
    }
}
*/
