import BaseItem from '../../../base/view/item';
import BaseCollection from '../../../base/collection/base';
import CoverageStatusView from './coverage-status';
import FamilyInsuranceSteppersCollectionView from './family-insurance-steppers';
import InsuranceAssignmentsView from './insurance-assignments';
import ApiStepFactory from '../../../item/api-step/factory';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/item/insurance-selector/layout.hbs';
import * as Constants from '../../../config/constants';


export default class InsuranceSelectorView extends BaseItem {
    constructor (options = {}) {
        super({
            key: 'insurance-selector', templateWebpack, ...options
        });

        this.listenTo(this.model, 'change', () => {
            this.render();
        });

        this.listenTo(app, 'change:insurance-selector', () => {
            this.triggerMethod('step:did:change');
        });
    }


    /**
     * View events
     */
    events () {
        return {
            'click .js-insurance-selector-without': this.onClickWithout,
            'click .js-insurance-selector-with': this.onClickWith,
            'click .insurance-policy-stepper .number-input-element .button': (e) => {
                e.preventDefault();
                const $button = this.$(e.currentTarget);
                const policyType = $button.parents('.insurance-policy').data('type');
                if ($button.hasClass('button-plus')) {
                    this.model.addInsuranceByType(policyType);
                } else {
                    this.model.removeInsurancesByType(policyType);
                }
            },
            'click .button-show-insurances': (e) => {
                e.preventDefault();
                this.submitPolicySelection()
                    .then(() => {
                        this.model.set('showInsurancesAssignment', true);
                        app.trigger('change:insurance-selector');
                    });
            },
            'change .js-accept-consulting-protocol': (e) => { // TUICIBE-554
                const formGroup = document.querySelector('#accept-consulting-protocol-wrapper');
                const formError = document.querySelector('#accept-consulting-protocol-wrapper .form-error');
                if (formGroup && formError) {
                    if (e.currentTarget.checked) {
                        formGroup.classList.remove('has-errors');
                        formError.classList.add('hidden');
                    } else {
                        formGroup.classList.add('has-errors');
                        formError.classList.remove('hidden');
                    }
                }
                this.model.acceptConsultingProtocol(e.currentTarget.checked);
                this.triggerMethod('step:did:change');
            }
        };
    }


    /**
     * Listen to events of child views
     */
    childViewEvents () {
        return {
            'edit:insurances': () => {
                this.model.set('showInsurancesAssignment', false);
                app.trackController.eventTracking({
                    action: 'Versicherung', label: 'Empfehlung|Editieren', ga4clickLabel: 'content.icon.reiseversicherung-empfehlung-editieren'
                });
            },
            'step:did:change': () => {
                this.triggerMethod('step:did:change');
            }
        };
    }

    regions () {
        return {
            'coverage-status': {
                el: '.insurance-coverage-status-region', replaceElement: true
            },
            'family-insurances': {
                el: '.insurance-family-insurances-region', replaceElement: true
            },
            'insurance-assigment': {
                el: '.insurance-assigment-region', replaceElement: true
            },
            [Constants.NETMATCH_STEP_INSURANCETERMSANDCONDITIONS]: {
                el: '.api-step-region-insurance-termsandconditions', replaceElement: true
            }
        };
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        const isB2B = app.apiSession.bookingStatus.agency.isB2B();
        const b2bMessages = app.contentApi.messages.prepareMessages('b2blight');

        return {
            individualInsurancesCount: this.model.getIndividualInsurancesCount(),
            coupleInsurancesCount: this.model.getCoupleInsurancesCount(),
            isCoupleInsuranceAvailable: this.model.isCoupleInsuranceAvailable(),
            familyInsurancesCount: this.model.getFamilyInsurancesCount(),
            isFamilyInsuranceAvailable: this.model.isFamilyInsuranceAvailable(),
            consultingProtocolAccepted: this.model.consultingProtocolAccepted,
            isB2BOperator: isB2B,
            enableShowInsurancesButton: this.model.getTotalInsuranceCount() && this.model.hasValidInsuranceSelection(), // beware: the consultingProtocol from the B2B messages gets displayed to NONE B2B users
            consultingProtocolUrl: !isB2B ? b2bMessages.consultingProtocol : null
        };
    }

    onRender () {
        this.showChildView('coverage-status', new CoverageStatusView({
            model: this.model
        }));
        if (!this.model.get('showInsurancesWaiver')) {
            if (this.model.get('showInsurancesAssignment')) {
                this.showChildView('insurance-assigment', new InsuranceAssignmentsView({
                    collection: this.model.insurances, insuranceSelectorModel: this.model
                }));

                const termsAndConditionsStep = this.model.getApiStepTermsAndConditions();
                const termsAndConditionsStepView = ApiStepFactory.create(termsAndConditionsStep.id, {
                    model: termsAndConditionsStep
                });
                this.showChildView(Constants.NETMATCH_STEP_INSURANCETERMSANDCONDITIONS, termsAndConditionsStepView);
            } else if (this.model.isFamilyInsuranceAvailable()) {
                const familyInsuranceCollection = new BaseCollection(this.model.getFamilyInsurances());
                this.showChildView('family-insurances', new FamilyInsuranceSteppersCollectionView({
                    collection: familyInsuranceCollection
                }));
            }
        }
    }

    onClickWith (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!this.$el.find(e.currentTarget).parent().hasClass('is-selected')) {
            this.model.handleRecommendations().then(() => {
                this.triggerMethod('step:did:change');
                this.model.set({
                    showInsurancesWaiver: false
                });
            });
        }
    }

    onClickWithout (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!this.$el.find(e.currentTarget).parent().hasClass('is-selected')) {
            this.model.set('showInsurancesWaiver', true);
            this.model.set('showInsurancesAssignment', false);
            // this.model.insurances.reset();
            this.model.setNoInsurance().then(() => {
                this.triggerMethod('step:did:change');
            });

            app.trackController.eventTracking({
                action: 'Versicherung', label: 'Ohne Versicherung reisen', ga4clickLabel: 'content.icon.reiseversicherung-ohne-versicherung-reisen'
            });
        }
    }

    submitPolicySelection () {
        if (!this.model.hasValidInsuranceSelection()) {
            /* eslint prefer-promise-reject-errors: 0 */
            return Promise.reject();
        }
        return this.model.submitInsurancePolicyStep();
    }
}
