import {getIntl} from '../intl';

export const STORAGE_KEY = 'ibe_store';
export const NETMATCH_API_SESSION_ID_KEY = 'api_sid';
export const APP_BACK_LINK = 'app_backlink';
export const INITIAL_PARAMETER = 'initial-parameter';
export const ALL_QUERY_PARAMETER = 'init-query';
export const CONFIRM_STATUS = 'isCompleted';
export const IS_ROUNDTRIP = 'isRoundTrip';
export const TRACKING_PARAMETER = 'tracking';


/**
 * Constants related to the ibe running inside a native app
 */
export const NATIVE_APP_TRIGGER_PARAMETER = 'app';
export const NATIVE_APP_CLASS_NAME = 'runs-in-native-app';

export const TUIC_OPT_OUT_COOKIE_NAME = 'tuic-ibe-opt-out';
/**
 * Constants Page Route
 */
export const PAGE_CABIN = 'cabin';
export const PAGE_CABINNUMBER = 'cabinnumber';
export const PAGE_TRAVEL = 'travel';
export const PAGE_INSURANCE = 'insurance';
export const PAGE_PERSONAL = 'personal';
export const PAGE_OVERVIEW = 'overview';
export const PAGE_PAYMENT = 'payment';
export const PAGE_CONFIRM = 'confirm';


/**
 * Constants for ga4 Tracking
 */

export const TRACK_PAYMENT_ADD_INFO = 'payment_add_info';
export const TRACK_ADD_TO_CART = 'add_to_cart';


/**
 * Constants for the booking page status
 */
export const BOOKING_PAGE_STATUS_PENDING = 'pending';
export const BOOKING_PAGE_STATUS_OPEN = 'open';
export const BOOKING_PAGE_STATUS_DONE = 'done';
export const BOOKING_PAGE_STATUS_NOT_APPLICABLE = 'not-applicable';

/**
 * Constants for the initial parameters
 */
export const INITIAL_PARAMETER_TRIPCODE = 'tripCode';
export const INITIAL_PARAMETER_EPACKAGECODE = 'ePackageCode';
export const INITIAL_PARAMETER_DEPARTUREDATE = 'departureDate';
export const INITIAL_PARAMETER_ADULTS = 'adults';
export const INITIAL_PARAMETER_CHILDREN = 'childs';
export const INITIAL_PARAMETER_BABIES = 'babies';
export const INITIAL_PARAMETER_CABINTYPE = 'cabinType';
export const INITIAL_PARAMETER_AIRPORT = 'airport';
export const INITIAL_PARAMETER_SHIP_BOUND_AIRPORT = 'shipBoundAirport';
export const INITIAL_PARAMETER_HOME_BOUND_AIRPORT = 'homeBoundAirport';
export const INITIAL_PARAMETER_TRANSPORTTYPE_TYPE = 'flight';
export const INITIAL_PARAMETER_BACK_LINK = 'backLink';
export const INITIAL_PARAMETER_API_SID = 'api_sid';
export const INITIAL_PARAMETER_OPT_OUT = 'tracking';
export const INITIAL_PARAMETER_AGENCY_NUMBER = 'agency_number';
export const INITIAL_PARAMETER_ITEM_CATEGORY = 'itemCategory';
export const INITIAL_PARAMETER_EC_LIST_NAME = 'ecListName';
export const INITIAL_PARAMETER_EC_LIST_CODE = 'ecListCode';
export const INITIAL_PARAMETER_EC_LIST_POSITION = 'ecListPosition';
export const INITIAL_PARAMETER_EC_LIST_PAGE = 'ecListPage';
export const INITIAL_PARAMETER_LOCALE = 'locale';

export const FALLBACK_BACKLINK = 'https://www.meinschiff.com/kreuzfahrt-buchen';

/**
 * TUICUNIT-2311: Constants for the Recovery Tracking parameters
 */
export const CLOUDIQ_RECOVERY_UTM_CAMPAIGN = 'utm_campaign';
export const CLOUDIQ_RECOVERY_UTM_CONTENT = 'utm_content';
export const CLOUDIQ_RECOVERY_UTM_ID = 'utm_id';
export const CLOUDIQ_RECOVERY_UTM_MEDIUM = 'utm_medium';
export const CLOUDIQ_RECOVERY_UTM_SOURCE = 'utm_source';
export const CLOUDIQ_RECOVERY_UTM_TERM = 'utm_term';


/**
 * Constants for the api steps status
 */
export const NETMATCH_STEP_STATUS_OPEN = 'Open';
export const NETMATCH_STEP_STATUS_PENDING = 'Pending';
export const NETMATCH_STEP_STATUS_OPTIONAL = 'Optional';
export const NETMATCH_STEP_STATUS_COMPLETED = 'Completed';
export const NETMATCH_STEP_STATUS_COMPLETED_AUTOMATICALLY = 'Completed automatically';
export const NETMATCH_STEP_STATUS_COMPLETED_WITH_DEFAULT = 'Completed with default';
export const NETMATCH_STEP_STATUS_NOT_APPLICABLE = 'Not applicable';
export const NETMATCH_STEP_STATUS_BLOCKED = 'Blocked';
export const NETMATCH_STEP_STATUS_FAILED = 'Failed';
export const NETMATCH_STEP_STATUS_ERROR = 'Error';

export const CELLULAR_STEP_STATUS_CONFIRM_TIMEOUT = 'TimeOut';


/**
 * Constants containing regular expressions that the step ids and the input ids of the ibe / netmatch api.
 */

// booking page: CABIN
export const NETMATCH_STEP_CABIN_SPLIT_BY = '-';
export const NETMATCH_STEP_OCCUPANT_SPLIT_BY = '.';

export const NETMATCH_STEP_CABINCOUNT = 'cabinCount';
export const NETMATCH_INPUT_CABINCOUNT = 'Cabins';

export const NETMATCH_STEP_CLIENT = 'client';
export const NETMATCH_STEP_LANGUAGE = 'language';
export const NETMATCH_INPUT_CLIENT = 'Client';
export const NETMATCH_INPUT_LANGUAGE_CODE = 'LanguageCode';
export const NETMATCH_OPTION_CLIENT_APP = 'App';
export const NETMATCH_OPTION_CLIENT_WEB = 'Website';

export const NETMATCH_STEP_TRIP = 'trip/cabin-1';
export const NETMATCH_INPUT_TRIP_AUFTRAGSNUMMER = 'TripCode';
export const NETMATCH_INPUT_TRIP_EPACKAGECODE = 'EPackageCode';
export const NETMATCH_INPUT_TRIP_CRUISESTARTDATE = 'CruiseStartDate';

export const NETMATCH_STEP_TRIP_ALTERNATIVES = 'tripAlternatives';
export const INJECT_NETMATCH_INPUT_TRIP_ALTERNATIVE = 'tripAlternative';

export const NETMATCH_STEP_PARTY = 'party/cabin-(\\d+)';
export const NETMATCH_INPUT_PARTY_ADULTS = 'Adults';
export const NETMATCH_INPUT_PARTY_CHILDREN = 'Children';

export const NETMATCH_STEP_CABINTYPE = 'cabinType/cabin-(\\d+)';
export const NETMATCH_STEP_TYPE_CABINTYPE = 'cabinType';
export const NETMATCH_INPUT_CABINTYPE_CABINTYPE = 'CategoryTypeCode';
export const NETMATCH_INPUT_CABINTYPE_OPTION_ID_FAMILY = 'Familienkabine';
export const NETMATCH_INPUT_CABINTYPE_OPTION_ID_SUITE = 'Suite';

export const NETMATCH_STEP_CABINCATEGORY = 'cabinCategory/cabin-(\\d+)';
export const NETMATCH_INPUT_CABINCATEGORY_CATEGORYCODE = 'CategoryCode';
export const NETMATCH_INPUT_CABINCATEGORY_EXTENDSHORTLISTWITH = 'ExtendShortListWith';

export const NETMATCH_STEP_CABINFLOW = 'cabinFlow/cabin-(\\d+)';
export const NETMATCH_STEP_CABINFLOW_PURE = 'cabinFlow/cabin-';
export const NETMATCH_INPUT_CABINFLOW = 'Flow';
export const NETMATCH_OPTION_CABINFLOW_CABINNUMBER = 'selectCabinNumber';
export const NETMATCH_OPTION_CABINFLOW_CATEGORY_ONLY = 'selectCategoryOnly';

export const NETMATCH_STEP_CABINDECK = 'cabinDeck/cabin-(\\d+)';
export const NETMATCH_STEP_TYPE_CABINDECK = 'cabinDeck';
export const NETMATCH_INPUT_CABINDECK_DECKNUMBER = 'DeckNumber';

export const NETMATCH_STEP_CABINNUMBER = 'cabinSelection/cabin-(\\d+)';
export const NETMATCH_INPUT_CABINNUMBER_CABINNUMBER = 'CabinNumber';

export const NETMATCH_STEP_CABINVIP = 'vip/cabin-(\\d+)';
export const NETMATCH_STEP_TYPE_CABININVIP = 'vip';
export const NETMATCH_STEP_CABINVIP_PURE = 'vip/cabin-';
export const NETMATCH_INPUT_CABININVIP = 'VipTariff';
// TUICUNIT-3084: New VIP Handling
export const NETMATCH_OPTION_CABININVIP_KEEP = 'none';
export const NETMATCH_OPTION_CABININVIP_KEEP_OLD_NAMEING = 'keepEarlyBirdDiscount';
export const NETMATCH_OPTION_CABININVIP_VIP1 = 'vipTariff1';
export const NETMATCH_OPTION_CABININVIP_VIP2 = 'vipTariff2';

// booking page: TRAVEL
export const INFO_TRAVEL_STEP_HEADLINE = getIntl().formatMessage({id: 'constants.INFO_TRAVEL_STEP_HEADLINE'});
export const INFO_TRAVEL_STEP_SUB_HEADLINE = getIntl().formatMessage({id: 'constants.INFO_TRAVEL_STEP_SUB_HEADLINE'});
export const NETMATCH_STEP_SHIPBOUNDTRANSPORTTYPE = 'shipBoundTransportType';
export const NETMATCH_STEP_HOMEBOUNDTRANSPORTTYPE = 'homeBoundTransportType';
export const NETMATCH_STEP_TYPE_SHIPBOUNDTRANSPORTTYPE = 'shipBoundTransportType';
export const NETMATCH_STEP_TYPE_HOMEBOUNDTRANSPORTTYPE = 'homeBoundTransportType';
export const NETMATCH_INPUT_TRANSPORTTYPE_TYPE = 'Type';

export const NETMATCH_STEP_SHIPBOUNDRAILWAYSTATION_DEPRECATED = 'shipBoundRailwayStation';
export const NETMATCH_STEP_HOMEBOUNDRAILWAYSTATION_DEPRECATED = 'homeBoundRailwayStation';
export const NETMATCH_INPUT_RAILWAYSTATION_STATIONID_DEPRECATED = 'StationId';
export const NETMATCH_INPUT_RAILWAYSTATION_STATIONNAME_DEPRECATED = 'StationName';

export const NETMATCH_STEP_SHIPBOUNDTRAIN = 'shipBoundTrain';
export const NETMATCH_STEP_HOMEBOUNDTRAIN = 'homeBoundTrain';
export const NETMATCH_STEP_TYPE_SHIPBOUNDTRAIN = 'shipBoundTrain';
export const NETMATCH_STEP_TYPE_HOMEBOUNDTRAIN = 'homeBoundTrain';
export const NETMATCH_INPUT_TRAIN_SERVICEID = 'TrainId';
export const NETMATCH_INPUT_TRAIN_TRAVELCLASS = 'Klasse';
export const INFO_TRAIN_UNAVAILABLE = getIntl().formatMessage({
    id: 'constants.INFO_TRAIN_UNAVAILABLE'
}, {
    a: (chunks) => `<a class="js-overlay-serviceteam underline">${chunks}</a>`
});
export const NETMATCH_ATTRIBUTES_RAILWAYSTATION_KEY_DEPRECATED = 'Railway Station';
// map "Short" and "Long" to
export const NETMATCH_TRAIN_OPTION_MAP_RAILCRUISE = 'RailCruise';
export const NETMATCH_TRAIN_OPTION_FLEXIBLEFARE = 'FlexibleFare';
export const NETMATCH_TRAIN_OPTION_SAVERFARE = 'SaverFare';

export const NETMATCH_STEP_SHIPBOUNDBUS = 'shipBoundBus';
export const NETMATCH_STEP_HOMEBOUNDBUS = 'homeBoundBus';
export const NETMATCH_STEP_TYPE_SHIPBOUNDBUS = 'shipBoundBus';
export const NETMATCH_STEP_TYPE_HOMEBOUNDBUS = 'homeBoundBus';
export const NETMATCH_INPUT_BUS_BUSID = 'BusId';

export const NETMATCH_STEP_SHIPBOUNDAIRPORT = 'shipBoundAirport';
export const NETMATCH_STEP_HOMEBOUNDAIRPORT = 'homeBoundAirport';
export const NETMATCH_STEP_TYPE_SHIPBOUNDAIRPORT = 'shipBoundAirport';
export const NETMATCH_STEP_TYPE_HOMEBOUNDAIRPORT = 'homeBoundAirport';
export const NETMATCH_STEP_TYPE_POLICY = 'policyType';
export const NETMATCH_INPUT_AIRPORT_AIRPORTCODE = 'AirportCode';

export const NETMATCH_STEP_SHIPBOUNDFLIGHT = 'shipBoundFlight';
export const NETMATCH_STEP_HOMEBOUNDFLIGHT = 'homeBoundFlight';
export const NETMATCH_STEP_TYPE_SHIPBOUNDFLIGHT = 'shipBoundFlight';
export const NETMATCH_STEP_TYPE_HOMEBOUNDFLIGHT = 'homeBoundFlight';

export const NETMATCH_INPUT_FLIGHT_FLIGHTID = 'FlightOptionId';
export const NETMATCH_INPUT_FLIGHT_TRAINTOPLANECLASSUPGRADE = 'TrainToPlaneClassUpgrade';
export const NETMATCH_INPUT_FLIGHT_TRAINTOPLANEUPGRADEOPTIONID = 'TrainToPlaneUpgradeOptionId';

// booking page: INSURANCE

export const NETMATCH_STEP_INSURANCEPOLICIES = 'insurancePolicies';
export const NETMATCH_INPUT_INSURANCE_INDIVIDUALCOUNT = 'IndividualCount';
export const NETMATCH_INPUT_INSURANCE_GROUPCOUNT = 'GroupCount';
export const NETMATCH_INPUT_INSURANCE_COUPLECOUNT = 'CoupleCount';
export const NETMATCH_INPUT_INSURANCE_FAMILYCOUNT = 'FamilyCount';
export const NETMATCH_INPUT_INSURANCE_FAMILY_ADULTCOUNT = 'AdultCount';
export const NETMATCH_INPUT_INSURANCE_FAMILY_CHILDCOUNT = 'ChildCount';
export const NETMATCH_INPUT_INSURANCE_DUMMYCOUNT = 'DummyCount';
// export const NETMATCH_INPUT_INSURANCE_FAMILYSETS = 'FamilySets';

// export const NETMATCH_STEP_POLICYASSIGNMENT = 'policyAssignment.(\\d+)';
export const NETMATCH_STEP_POLICYASSIGNMENT = 'policyAssignment/policy-(\\d+)';
export const NETMATCH_INPUT_ASSIGN_TO = 'AssignTo';
// export const NETMATCH_SPECIAL_INPUT_ASSIGN_TO = 'AssignTo@odata.type';
// export const NETMATCH_SPECIAL_INPUT_VALUE_ASSIGN_TO = '#Collection(Byte)';

// export const NETMATCH_STEP_POLICYTYPE = 'policyType.(\\d+)';
export const NETMATCH_STEP_POLICYTYPE = 'policyType/policy-(\\d+)';
export const NETMATCH_INPUT_POLICY = 'Policy';
export const NETMATCH_SPECIAL_INPUT_VALUE_DUMMY_POLICY = 'SRV';

export const NETMATCH_STEP_INSURANCETERMSANDCONDITIONS = 'insuranceTermsAndConditions';
export const NETMATCH_STEP_POLICYTYPE_NONE_DISPLAYTEXT = 'Ohne Versicherungsschutz reisen';

export const INFO_INSURER_CLAIM = getIntl().formatMessage({id: 'constants.INFO_INSURER_CLAIM'});
export const INFO_INSURER_LOGOCLASS = 'hansemerkur';

export const INFO_INSURANCE_POLICIES_STEP_HEADLINE = getIntl().formatMessage({id: 'constants.INFO_INSURANCE_POLICIES_STEP_HEADLINE'});

export const INFO_INSURANCE_RETENTION_REQUIRED_TEXT = getIntl().formatMessage({id: 'constants.INFO_INSURANCE_RETENTION_REQUIRED_TEXT'});
export const INFO_INSURANCE_RETENTION_NOT_REQUIRED_TEXT = getIntl().formatMessage({id: 'constants.INFO_INSURANCE_RETENTION_NOT_REQUIRED_TEXT'});

// booking page: PERSONAL

export const NETMATCH_STEP_ADULT = 'adult/occupant-(\\d+).(\\d+)';
export const NETMATCH_INPUT_ADULT_SALUTATIONCODE = 'SalutationCode';
export const NETMATCH_INPUT_ADULT_TITLE = 'Title';
export const NETMATCH_INPUT_ADULT_FIRSTNAME = 'FirstName';
export const NETMATCH_INPUT_ADULT_LASTNAME = 'LastName';
export const NETMATCH_INPUT_ADULT_DATEOFBIRTH = 'DateOfBirth';
export const NETMATCH_INPUT_ADULT_NATIONALITY = 'Nationality';

export const NETMATCH_STEP_CHILD = 'child/occupant-(\\d+).(\\d+)';
export const NETMATCH_INPUT_CHILD_GENDER = 'Gender';
export const NETMATCH_INPUT_CHILD_FIRSTNAME = 'FirstName';
export const NETMATCH_INPUT_CHILD_LASTNAME = 'LastName';
export const NETMATCH_INPUT_CHILD_DATEOFBIRTH = 'DateOfBirth';
export const NETMATCH_INPUT_CHILD_NATIONALITY = 'Nationality';

export const NETMATCH_STEP_INVOICE = 'invoice';
export const NETMATCH_INPUT_INVOICE_SALUTATIONCODE = 'SalutationCode';
export const NETMATCH_INPUT_INVOICE_TITLE = 'Title';
export const NETMATCH_INPUT_INVOICE_FIRSTNAME = 'FirstName';
export const NETMATCH_INPUT_INVOICE_LASTNAME = 'LastName';
export const NETMATCH_INPUT_INVOICE_DATEOFBIRTH = 'DateOfBirth';
export const NETMATCH_INPUT_INVOICE_STREETANDHOUSENUMBER = 'StreetAndHouseNumber';
export const NETMATCH_INPUT_INVOICE_ADDITIONALADDRESS = 'AdditionalAddress';
export const NETMATCH_INPUT_INVOICE_POSTALCODE = 'PostalCode';
export const NETMATCH_INPUT_INVOICE_CITY = 'City';
export const NETMATCH_INPUT_INVOICE_COUNTRY = 'CountryCode';
export const NETMATCH_INPUT_INVOICE_TELEPHONE = 'Telephone';
export const NETMATCH_INPUT_INVOICE_MOBILENUMBER = 'MobileNumber';
export const NETMATCH_INPUT_INVOICE_EMAIL = 'Email';
export const NETMATCH_INPUT_INVOICE_EMAILREPEAT = 'EmailRepeat';


export const RESTOREBOOKING_RESET_NETMATCH_STEPS = [
    {id: 'invoice'},
    {id: 'adults/*'},
    {id: 'children/*'}

];


// booking page: OVERVIEW
export const NETMATCH_STEP_APPROVAL = 'overviewAndApproval';
export const NETMATCH_INPUT_APPROVAL_OF_TERMS = 'ApprovalOfTerms';
export const NETMATCH_INPUT_SUBSCRIBE_TO_NEWSLETTER = 'SubscribeToNewsletter';

// booking page: PAYMENT
export const NETMATCH_STEP_ONLINE_RISK = 'onlineRisk';
export const NETMATCH_ONLINE_RISK_NO_RISK = 2;
export const NETMATCH_ONLINE_RISK_NO_BOOKING_NEGATIVE_CREDIT = 4;
export const NETMATCH_ONLINE_RISK_NO_BOOKING_BLACKLISTED = 8;
export const NETMATCH_ONLINE_RISK_NO_DIRECT_DEBIT = 16;
export const NETMATCH_ONLINE_RISK_BLACKLISTED_TRAVELER = 32;
export const NETMATCH_ONLINE_RISK_NO_BOOKING_UNKNOWN_REASON = 64;
export const NETMATCH_ONLINE_RISK_UNKNOWN = 1024;

export const NETMATCH_STEP_PAYMENT = 'paymentType';
export const NETMATCH_INPUT_PAYMENT_OPTION = 'Type';

export const NETMATCH_STEP_PAYMENT_SEPA = 'sepa';
export const NETMATCH_STEP_PAYMENT_API_RESULT_SEPA = 'SEPA';
export const NETMATCH_INPUT_PAYMENT_SEPA_BIC = 'BIC';
export const NETMATCH_INPUT_PAYMENT_SEPA_BANKNAME = 'BankName';
export const NETMATCH_INPUT_PAYMENT_SEPA_IBAN = 'IBAN';
export const NETMATCH_INPUT_PAYMENT_SEPA_ACCOUNTHOLDERNAME = 'AccountHolderName';

export const NETMATCH_STEP_PAYMENT_CREDITCARD = 'creditCardToken';
export const NETMATCH_STEP_PAYMENT_API_RESULT_CREDITCARD = 'creditCard';
export const NETMATCH_INPUT_PAYMENT_CREDITCARD_TRANSACTIONID = 'TransactionId';
export const NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDBRAND = 'CreditCardBrand';
export const NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDNUMBER = 'CreditCardNumber';
export const NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDVALID = 'CreditCardValid';
export const NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDOWNER = 'CreditCardOwner';
export const NETMATCH_INPUT_PAYMENT_CREDITCARD_TOKEN = 'Token';
export const NETMATCH_INPUT_PAYMENT_CREDITCARD_HASH = 'Hash';
export const NETMATCH_INPUT_PAYMENT_CREDITCARD_STATUS = 'Status';
export const NETMATCH_INPUT_PAYMENT_CREDITCARD_STATUSDESCRIPTION = 'StatusDescription';
export const NETMATCH_INPUT_PAYMENT_CREDITCARD_RETURNCODE = 'Returncode';
export const NETMATCH_INPUT_PAYMENT_CREDITCARD_TIMESTAMP = 'TimeStamp';
export const NETMATCH_INPUT_PAYMENT_CREDITCARD_BIN = 'Bin';

export const NETMATCH_STEP_PAYMENT_BANK_TRANFER = 'nonSepa';
export const NETMATCH_STEP_PAYMENT_API_RESULT_BANK_TRANFER = 'non-SEPA';
export const NETMATCH_INPUT_PAYMENT_BANK_TRANFER_ACKNOWLEDGED = 'Acknowledged';
export const NETMATCH_INPUT_PAYMENT_CREDIT_CARD_BRAND = 'Brand';


// booking page: CONFIRM
export const NETMATCH_STEP_CONFIRM = 'confirmation';
export const NETMATCH_STEP_CONFIRM_RESULT_INITIALSUBMIT = 'initialSubmit';
export const NETMATCH_STEP_CONFIRM_RESULT_CREATE_RESERVATION = 'Create reservation';
export const NETMATCH_STEP_CONFIRM_RESULT_SUCCESSOR_SESSION = 'Successor session';

export const NETMATCH_STEP_CONFIRM_CHECK_MAXIMUM = 12;

// B2B
export const TUIC_B2B_AGENCY_COOKIE_NAME = 'AgenturNummer';
export const TUIC_B2B_AUTH_URL_FALLBACK = 'https://meinschiff.com/infonet/login/';
export const TUIC_B2B_BACK_URL_FALLBACK = 'https://legacy.meinschiff.com/infonet/preise/';
export const NETMATCH_STEP_AGENCY = 'agency';
export const NETMATCH_INPUT_AGENCY_AGENCYNUMBER = 'AgencyNumber';
export const NETMATCH_STEP_AGENCY_API_RESULT_PAYMENT_TYPE_OPERATOR = 'tour operator inkasso';
export const NETMATCH_STEP_AGENCY_API_RESULT_PAYMENT_TYPE_AGENTUR = 'agentur inkasso';

// Special regular expressions that match multiple steps
export const NETMATCH_STEP_ALL_BUT_PAYMENT_AND_OVERVIEW = '^(?!payment|overview)(^.*)$';
export const NETMATCH_STEP_ALL = '.*';


/**
 * Constants for the api validator names
 */
export const NETMATCH_VALIDATOR_TYPE_REQUIRED = 'Required';
export const NETMATCH_VALIDATOR_TYPE_RANGE = 'Range';
export const NETMATCH_VALIDATOR_TYPE_DATATYPE = 'DataType';
export const NETMATCH_VALIDATOR_TYPE_MUSTMATCHONEFROMCOLLECTION = 'MustMatchOneFromCollection';
export const NETMATCH_VALIDATOR_TYPE_REGULAREXPRESSION = 'RegularExpression';
export const NETMATCH_VALIDATOR_TYPE_DATERANGE = 'MovingDateRange';
export const NETMATCH_VALIDATOR_TYPE_MINLENGTH = 'MinLength';
export const NETMATCH_VALIDATOR_TYPE_MAXLENGTH = 'MaxLength';
export const NETMATCH_VALIDATOR_TYPE_EMAILADDRESS = 'EmailAddress';
export const NETMATCH_VALIDATOR_TYPE_VALIDAGENCYID = 'ValidAgencyId';
export const NETMATCH_VALIDATOR_TYPE_VALIDCRUISECODE = 'ValidCruiseCode';
export const NETMATCH_VALIDATOR_TYPE_VALIDPARTYCOMPOSITION = 'ValidPartyComposition';
export const NETMATCH_VALIDATOR_TYPE_VALIDTRAINSTATION = 'ValidTrainStation';
export const NETMATCH_VALIDATOR_TYPE_VALIDIBAN = 'ValidIban';
export const NETMATCH_VALIDATOR_TYPE_MAXPOLICIES = 'MaxPolicies';
export const NETMATCH_VALIDATOR_TYPE_EQUALS = 'Equals';
export const NETMATCH_VALIDATOR_TYPE_VALIDPARTICIPANTINDEXES = 'ValidParticipantIndexes';
export const NETMATCH_VALIDATOR_TYPE_LENGTH = 'Length';
export const NETMATCH_VALIDATOR_TYPE_COMPARE = 'Compare';
export const NETMATCH_VALIDATOR_TYPE_CASEINSENSITIVECOMPARE = 'CaseInsensitiveCompare';
export const NETMATCH_VALIDATOR_TYPE_MINIMUMOFFSETDATE = 'MinimumOffsetDate';
export const NETMATCH_VALIDATOR_TYPE_MAXIMUMOFFSETDATE = 'MaximumOffsetDate';
export const NETMATCH_VALIDATOR_TYPE_ADULTSLENGTH = 'AdultsLength';
export const NETMATCH_VALIDATOR_TYPE_CHILDRENLENGTH = 'ChildrenLength';

export const NETMATCH_VALIDATOR_TYPE_VALIDTRIPCODE = 'ValidTripCode';
export const NETMATCH_VALIDATOR_TYPE_VALIDEPACKAGECODE = 'ValidEpackageCode';
export const NETMATCH_VALIDATOR_TYPE_VALIDTRAINSTATIONID = 'ValidTrainStationId';
export const NETMATCH_VALIDATOR_TYPE_VALIDTRAINSTATIONNAME = 'ValidTrainStationName';
export const NETMATCH_VALIDATOR_TYPE_VALIDCOUNTRYCODE = 'ValidCountryCode';
export const NETMATCH_VALIDATOR_TYPE_VALIDNATIONALITY = 'ValidNationality';
export const NETMATCH_VALIDATOR_TYPE_MUSTMATCHCONTEXREGEX = 'MustMatchContextRegex';
export const NETMATCH_VALIDATOR_TYPE_NOTEQUALTRAINSTATION = 'NotEqualToOppositeTrainStation';
export const NETMATCH_VALIDATOR_TYPE_MUSTMATCHREFERENCEINFLIGHTOPTIN = 'MustMatchReferenceInFlightOption';


// dumme komische neue validator
export const NETMATCH_VALIDATOR_TYPE_MAXINSURANCEPOLICIES = 'MaxInsurancePolicies';
export const NETMATCH_VALIDATOR_TYPE_INDIVIDUALPOLICIESRANGE = 'IndividualPoliciesRange';
export const NETMATCH_VALIDATOR_TYPE_COUPLEPOLICIESRANGE = 'CouplePoliciesRange';
export const NETMATCH_VALIDATOR_TYPE_FAMILYPOLICIESRANGE = 'FamilyPoliciesRange';
export const NETMATCH_VALIDATOR_TYPE_DUMMYPOLICIESRANGE = 'DummyPoliciesRange';
export const NETMATCH_VALIDATOR_TYPE_PARTICIPANTINDEXESLENGTH = 'ParticipantIndexesLength';
export const NETMATCH_VALIDATOR_TYPE_ADULTPARTICIPANTSTYPEINDEXESLENGTH = 'AdultParticipantsTypeIndexesLength';
export const NETMATCH_VALIDATOR_TYPE_CHILDPARTICIPANTSTYPEINDEXESLENGTH = 'ChildParticipantsTypeIndexesLength';
export const NETMATCH_VALIDATOR_TYPE_EQUALS_WHEN_OTHER = 'EqualsWhenOther';
export const NETMATCH_VALIDATOR_TYPE_REQUIRED_WHEN_OTHER = 'RequiredWhenOther';


/**
 * Constants for the api pricemodel names
 */
export const NETMATCH_PRICEMODEL_FLEX = 'Flex';
export const NETMATCH_PRICEMODEL_FEELGOOD = 'FeelGood';
export const NETMATCH_PRICEMODEL_FEELGOODPLUS = 'FeelGoodPlus';
export const NETMATCH_PRICEMODEL_PUR = 'Pur';
export const NETMATCH_PRICEMODEL_PLUS = 'Plus';
export const NETMATCH_PRICEMODEL_PRO = 'Pro';
// TUICUNIT-3084: New VIP Handling
export const NETMATCH_PRICEMODEL_HIDDENPROVIP1 = 'ProvipTariff1';
export const NETMATCH_PRICEMODEL_HIDDENPROVIP2 = 'ProvipTariff2';


export const YOURSTRULY_PRICEMODEL_FLEX = 'flexPrice';
export const YOURSTRULY_PRICEMODEL_FEELGOOD = 'feelgoodPrice';
export const YOURSTRULY_PRICEMODEL_FEELGOOD_PLUS = 'feelgoodPricePlus';
export const YOURSTRULY_PRICEMODEL_PUR = 'pur';
export const YOURSTRULY_PRICEMODEL_PLUS = 'plus';
export const YOURSTRULY_PRICEMODEL_PRO = 'pro';
export const YOURSTRULY_PRICEMODEL_PROVIP1 = 'provip1';
export const YOURSTRULY_PRICEMODEL_PROVIP2 = 'provip2';
export const YOURSTRULY_VIP1_TEXT = 'vipoption1';
export const YOURSTRULY_VIP2_TEXT = 'vipoption2';
export const YOURSTRULY_PROVIP1_TEXT = 'provipoption1';
export const YOURSTRULY_PROVIP2_TEXT = 'provipoption2';


/**
 * Constants for the api transport options
 */
export const NETMATCH_TRANSPORT_OPTION_FLIGHT = 'Flight';
export const NETMATCH_TRANSPORT_OPTION_TRAIN = 'Train';
export const NETMATCH_TRANSPORT_OPTION_BUS = 'Bus';
export const NETMATCH_TRANSPORT_OPTION_OWN = 'Own';

/**
 * Constants for the api Staus(previously session) type
 */
export const NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT = 'Flight';
export const NETMATCH_SESSION_TRANSPORT_TYPE_TRAIN = 'Train';
export const NETMATCH_SESSION_TRANSPORT_TYPE_BUS = 'Bus';
export const NETMATCH_SESSION_TRANSPORT_TYPE_OWN = 'Own';
export const NETMATCH_SESSION_INSURANCE_MEMBERSTYPE_DUMMY = 'Dummy';
export const NETMATCH_SESSION_CABIN_PRICEMODEL_FLEX = 'Flex-Preis';
export const NETMATCH_SESSION_CABIN_PRICEMODEL_FEELGOOD = 'Wohlfühlpreis';
export const NETMATCH_SESSION_CABIN_PRICEMODEL_FEELGOODPLUS = 'Wohlfühlpreis';
export const NETMATCH_SESSION_AGECATEGORY_ADULT = 'Adult';
export const NETMATCH_SESSION_AGECATEGORY_CHILD = 'Child';

export const NETMATCH_STATUS_CABIN_PRICEMODEL_PRO = 'PRO-Tarif';
export const NETMATCH_STATUS_CABIN_PRICEMODEL_PLUS = 'PLUS-Tarif';
export const NETMATCH_STATUS_CABIN_PRICEMODEL_PUR = 'PUR-Tarif';
export const NETMATCH_STATUS_PRICES_VIP_SERVICECATEGORY = 'VipTariff';

/**
 * Constants to distinguish the direction in the travel steps
 */
export const SHIPBOUND = 'shipBound';
export const HOMEBOUND = 'homeBound';


/**
 * Constants for the api insurance-policies  options
 */
export const NETMATCH_INSURANCE_KEY_BASIC = 'Basisschutz';
export const NETMATCH_INSURANCE_KEY_ADDITION_NO = 'Ergänzungsschutz';
export const NETMATCH_INSURANCE_KEY_ADDITION = 'Auslandskranken- und Notfall-Schutz';
export const NETMATCH_INSURANCE_KEY_TRAVELCANCEL = 'Reiserücktritt';
export const NETMATCH_INSURANCE_KEY_PREMIUM = 'Premiumschutz';
export const NETMATCH_INSURANCE_MEMBERSTYPE_INDIVIDUAL = 'individual';
export const NETMATCH_INSURANCE_MEMBERSTYPE_INDIVIDUAL_LABEL = 'pro Person';
export const NETMATCH_INSURANCE_MEMBERSTYPE_COUPLE = 'couple';
export const NETMATCH_INSURANCE_MEMBERSTYPE_COUPLE_LABEL = 'pro Paar';
export const NETMATCH_INSURANCE_MEMBERSTYPE_FAMILY = 'family';
export const NETMATCH_INSURANCE_MEMBERSTYPE_FAMILY_LABEL = 'pro Familie';
export const NETMATCH_INSURANCE_MEMBERSTYPE_DUMMY = 'dummy';
export const NETMATCH_INSURANCE_INTENTION = 'intent';
export const NETMATCH_INSURANCE_WAIVE = 'waive';


/**
 * Constants for campaign Code
 */
export const NETMATCH_CAMPAIGN_CODE_INVALID = 'ValidCampaignCode';
export const NETMATCH_CAMPAIGN_CODE_BORD_DEPOSITION_TYPE = 'BoardDeposit';
export const NETMATCH_CAMPAIGN_CODE_COMPATIBLE = 'CompatibleCampaignCodes';

export const YOURSTRULY_CAMPAIGN_CODE_BORD_DEPOSIT_TEXT = 'CampaignBordDeposit';
export const YOURSTRULY_CAMPAIGN_CODE_ALREADY_APPLIED_TEXT = 'CampaignCodeAlreadyApplied';
export const YOURSTRULY_CAMPAIGN_CODE_INVALID_TEXT = 'CampaignCodeInvalid';
export const YOURSTRULY_CAMPAIGN_CODE_NOT_COMPATIBLE_TEXT = 'CampaignCodeNotCompatible';
export const YOURSTRULY_CAMPAIGN_CODE_CONFIGURATION_CHANGE_TEXT = 'CampaignConfigurationChange';


/**
 * Constants for the session Values
 */
export const NETMATCH_SESSION_INVOICE_MALE = 'Herr';
export const NETMATCH_SESSION_INVOICE_FEMALE = 'Frau';

/**
 * Constants for the booking pager modes
 */
export const PAGER_MODE_NO_ACTION = 'No action';
export const PAGER_MODE_ACTIVATE_ON_PAGE_COMPLETE = 'Activate on page complete';
export const PAGER_MODE_VALIDATE_AND_SUBMIT = 'Validate and submit';


/**
 * Constants for type-ahead functionality
 */

export const TERM_TYPE_DEBOUNCE_TIMEOUT = 500;
export const TERM_MIN_LENGTH = 3;

/**
 * Constants for the api session type
 */
export const CONTENTAPI_INSURANCES_TRAVELCANCELATION = 'travelCancelation';
export const CONTENTAPI_INSURANCES_ADDITION = 'basic';
export const CONTENTAPI_INSURANCES_PREMIUM = 'premium';

/**
 * Constants for View
 */
export const VIEW_SERVICE_NUMBER_DISPLAY_CTA = '+4940600015111';
export const VIEW_SERVICE_NUMBER_DISPLAY_INT = '+49 40 60001-5111';
export const VIEW_SERVICE_NUMBER_DISPLAY_DE = '040 60001-5111';
export const VIEW_B2B_SERVICE_NUMBER_DISPLAY_CTA = '+4940600015222';
export const VIEW_B2B_SERVICE_NUMBER_DISPLAY_INT = '+49 40 60001-5222';
export const VIEW_B2B_SERVICE_NUMBER_DISPLAY_DE = '040 60001-5222';
export const VIEW_B2B_GROUP_NUMBER_DISPLAY_CTA = '+4940600015333';
export const VIEW_B2B_GROUP_NUMBER_DISPLAY_INT = '+49 40 60001-5333';


/**
 * Constants only for a TUIC Log script
 */
export const CONTENT_PROXY_ROOT_URL_TEST = 'https://cmware.test.meinschiff.com/ws/ibe-content/';
export const CONTENT_PROXY_ROOT_URL = 'https://cmware.meinschiff.com/ws/ibe-content/';
export const IBE_PROXY_ROOT_URL_TEST = 'https://tuicruises.tst.hosting.netmatch.eu/ibe/test/';
export const IBE_PROXY_ROOT_URL = 'https://tuicruises.prd.hosting.netmatch.eu/ibe/';

export const NM_SAVE_ERROR_TXT = getIntl().formatMessage({
    id: 'constants.nm_save_error_txt'
}, {
    a: (chunks) => `<a href="https://www.meinschiff.com/kontakt/reise-buchen" rel="noopener noreferrer" class="link">${chunks}</a>`
});
