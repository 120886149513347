var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "is-selected";
},"3":function(container,depth0,helpers,partials,data) {
    return "is-light";
},"5":function(container,depth0,helpers,partials,data) {
    return "checked";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"insurance-coverage-status-region\"></div>\n                    <div class=\"insurance-selector-waiver\">\n                        <p class=\"insurance-split-line\">\n                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.insurance-selector.layout.without.text1",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":18,"column":100}}})) != null ? stack1 : "")
    + "\n                        </p>\n                        <p>\n                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.insurance-selector.layout.without.text2",{"name":"formatMessage","hash":{"a":"#fnc#<a class=\"underline\" href=\"https://www.meinschiff.com/kundenservice/reiseschutz\" rel=\"noopener noreferrer\">$chunk$</a>"},"data":data,"loc":{"start":{"line":21,"column":28},"end":{"line":22,"column":177}}})) != null ? stack1 : "")
    + "\n                        </p>\n                    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInsurancesAssignment") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":125,"column":31}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInsurancesAssignment") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":20},"end":{"line":149,"column":27}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"insurance-selector-policy-quantities\">\n\n                            <div class=\"insurance-coverage-status-region\"></div>\n\n                            <h5 class=\"headline\">\n                                "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.insurance-selector.layout.with.description",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":46,"column":32},"end":{"line":46,"column":107}}})) != null ? stack1 : "")
    + "\n                            </h5>\n\n                            <section>\n                                <div class=\"insurance-policy\" data-type=\"individual\">\n                                    <div class=\"insurance-policy-stepper\">\n                                        <div class=\"number-input-element\">\n                                            <a class=\"button button-minus\"><span\n                                                    class=\"icon-magnifier-minus\"></span></a>\n                                            <input type=\"text\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"individualInsurancesCount") || (depth0 != null ? lookupProperty(depth0,"individualInsurancesCount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"individualInsurancesCount","hash":{},"data":data,"loc":{"start":{"line":55,"column":70},"end":{"line":55,"column":99}}}) : helper)))
    + "\"\n                                                   class=\"form-element\" readonly/>\n                                            <a class=\"button button-plus\"><span class=\"icon-magnifier-plus\"></span></a>\n                                        </div>\n                                    </div>\n                                    <div class=\"insurance-policy-name\">\n                                        "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.insurance-selector.layout.with.single",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":61,"column":40},"end":{"line":61,"column":110}}})) != null ? stack1 : "")
    + "\n                                    </div>\n                                    <div class=\"insurance-policy-icon\">\n                                        <span class=\"icon icon-insurance-single-empty\"></span>\n                                    </div>\n                                </div>\n                            </section>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCoupleInsuranceAvailable") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":28},"end":{"line":89,"column":35}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFamilyInsuranceAvailable") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":28},"end":{"line":113,"column":35}}})) != null ? stack1 : "")
    + "\n                            <div class=\"row\">\n                                <div class=\"col-sm-6 col-sm-push-6 align-right\">\n                                    <a class=\"button button-show-insurances js-button-show-insurances "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"enableShowInsurancesButton") : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":102},"end":{"line":118,"column":91}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.insurance-selector.layout.with.show",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":118,"column":93},"end":{"line":119,"column":94}}})) != null ? stack1 : "")
    + " <span\n                                            class=\"icon-small-arrow-right\"></span>\n                                    </a>\n                                </div>\n                            </div>\n                        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <section>\n                                    <div class=\"insurance-policy\" data-type=\"couple\">\n                                        <div class=\"insurance-policy-stepper\">\n                                            <div class=\"number-input-element\">\n                                                <a class=\"button button-minus\"><span\n                                                        class=\"icon-magnifier-minus\"></span></a>\n                                                <input type=\"text\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"coupleInsurancesCount") || (depth0 != null ? lookupProperty(depth0,"coupleInsurancesCount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"coupleInsurancesCount","hash":{},"data":data,"loc":{"start":{"line":76,"column":74},"end":{"line":76,"column":99}}}) : helper)))
    + "\"\n                                                       class=\"form-element\" readonly/>\n                                                <a class=\"button button-plus\"><span class=\"icon-magnifier-plus\"></span></a>\n                                            </div>\n                                        </div>\n                                        <div class=\"insurance-policy-name\">\n                                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.insurance-selector.layout.with.pair",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":82,"column":44},"end":{"line":82,"column":112}}})) != null ? stack1 : "")
    + "\n                                        </div>\n                                        <div class=\"insurance-policy-icon\">\n                                            <span class=\"icon icon-insurance-couple-empty\"></span>\n                                        </div>\n                                    </div>\n                                </section>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <section>\n                                    <div class=\"insurance-policy\" data-type=\"family\">\n                                        <div class=\"insurance-policy-stepper\">\n                                            <div class=\"number-input-element\">\n                                                <a class=\"button button-minus\"><span\n                                                        class=\"icon-magnifier-minus\"></span></a>\n                                                <input type=\"text\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"familyInsurancesCount") || (depth0 != null ? lookupProperty(depth0,"familyInsurancesCount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"familyInsurancesCount","hash":{},"data":data,"loc":{"start":{"line":98,"column":74},"end":{"line":98,"column":99}}}) : helper)))
    + "\"\n                                                       class=\"form-element\" readonly/>\n                                                <a class=\"button button-plus\"><span class=\"icon-magnifier-plus\"></span></a>\n                                            </div>\n                                        </div>\n                                        <div class=\"insurance-policy-name\">\n                                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.insurance-selector.layout.with.family",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":104,"column":44},"end":{"line":104,"column":114}}})) != null ? stack1 : "")
    + "\n                                        </div>\n                                        <div class=\"insurance-policy-icon\">\n                                            <span class=\"icon icon-insurance-family-empty\"></span>\n                                        </div>\n                                    </div>\n\n                                    <div class=\"insurance-family-insurances-region\"></div>\n                                </section>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isBlocked") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":129,"column":36},"end":{"line":129,"column":82}}})) != null ? stack1 : "")
    + "\">\n                            <div class=\"insurance-coverage-status-region\"></div>\n                            <div class=\"insurance-assigment-region\"></div>\n                            <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isBlocked") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":40},"end":{"line":132,"column":70}}})) != null ? stack1 : "")
    + "\">\n                                <div class=\"api-step-region-insurance-termsandconditions\"></div>\n                            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"consultingProtocolUrl") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":28},"end":{"line":147,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "insurances-are-blocked";
},"20":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"form-group\" id=\"accept-consulting-protocol-wrapper\">\n                                    <div class=\"checkbox-element "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isBlocked") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":65},"end":{"line":137,"column":95}}})) != null ? stack1 : "")
    + "\">\n                                        <input type=\"checkbox\" id=\"accept-consulting-protocol\"\n                                               class=\"js-accept-consulting-protocol\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"consultingProtocolAccepted") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":139,"column":85},"end":{"line":139,"column":133}}})) != null ? stack1 : "")
    + ">\n                                        <label for=\"accept-consulting-protocol\">\n                                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.insurance-selector.layout.protocol",{"name":"formatMessage","hash":{"a":"#fnc#<a class=\"underline\" href=\"' consultingProtocolUrl '\" rel=\"noopener noreferrer\">$chunk$</a>"},"data":data,"loc":{"start":{"line":141,"column":44},"end":{"line":142,"column":172}}})) != null ? stack1 : "")
    + "\n                                        </label>\n                                    </div>\n                                    <div class=\"form-error hidden\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.validation.required-field",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":145,"column":67},"end":{"line":145,"column":122}}})) != null ? stack1 : "")
    + "</div>\n                                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"insurance-selector\">\n\n    <div class=\"insurance-selector-tabview\">\n\n        <div class=\"insurance-selector-tabelement "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInsurancesWaiver") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":50},"end":{"line":5,"column":96}}})) != null ? stack1 : "")
    + "\">\n            <div class=\"js-insurance-selector-without insurance-selector-tab\" data-waiver=\"1\">\n                <div class=\"radiobutton-element "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInsurancesWaiver") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":48},"end":{"line":7,"column":99}}})) != null ? stack1 : "")
    + "\">\n                    <input type=\"radio\" id=\"insurance-selector-tab-radio-2\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInsurancesWaiver") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":76},"end":{"line":8,"column":118}}})) != null ? stack1 : "")
    + ">\n                    <label for=\"insurance-selector-tab-radio-2\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.insurance-selector.layout.without",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":9,"column":64},"end":{"line":10,"column":76}}})) != null ? stack1 : "")
    + "</label>\n                </div>\n            </div>\n            <div class=\"insurance-selector-tabcontent\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInsurancesWaiver") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":25,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"insurance-selector-tabelement "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInsurancesWaiver") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":50},"end":{"line":29,"column":104}}})) != null ? stack1 : "")
    + "\">\n            <div class=\"js-insurance-selector-with insurance-selector-tab is-last\">\n                <div class=\"radiobutton-element "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInsurancesWaiver") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":48},"end":{"line":31,"column":91}}})) != null ? stack1 : "")
    + "\">\n                    <input type=\"radio\" id=\"insurance-selector-tab-radio-1\"\n                           "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInsurancesWaiver") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":27},"end":{"line":33,"column":77}}})) != null ? stack1 : "")
    + ">\n                    <label for=\"insurance-selector-tab-radio-1\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.insurance-selector.layout.with.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":34,"column":64},"end":{"line":35,"column":82}}})) != null ? stack1 : "")
    + "</label>\n                </div>\n            </div>\n            <div class=\"insurance-selector-tabcontent\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInsurancesWaiver") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":150,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n    </div>\n</div>\n";
},"useData":true});