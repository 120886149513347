import _ from 'underscore';
import {Model} from 'backbone';
import * as Constants from '../../../config/constants';
import Url from '../../../util/url';


export default class IbeParametersModel extends Model {
    defaults () {
        return {
            [Constants.INITIAL_PARAMETER_TRIPCODE]: null,
            [Constants.INITIAL_PARAMETER_EPACKAGECODE]: null,
            [Constants.INITIAL_PARAMETER_DEPARTUREDATE]: null,
            [Constants.INITIAL_PARAMETER_ADULTS]: null,
            [Constants.INITIAL_PARAMETER_CHILDREN]: null,
            [Constants.INITIAL_PARAMETER_CABINTYPE]: null, // [Constants.INITIAL_PARAMETER_BABIES]: null,
            [Constants.INITIAL_PARAMETER_AIRPORT]: null,
            [Constants.INITIAL_PARAMETER_SHIP_BOUND_AIRPORT]: null,
            [Constants.INITIAL_PARAMETER_HOME_BOUND_AIRPORT]: null,
            [Constants.INITIAL_PARAMETER_TRANSPORTTYPE_TYPE]: null,
            [Constants.INITIAL_PARAMETER_BACK_LINK]: null,
            [Constants.INITIAL_PARAMETER_API_SID]: null,
            [Constants.INITIAL_PARAMETER_OPT_OUT]: null,
            [Constants.INITIAL_PARAMETER_AGENCY_NUMBER]: null,
            [Constants.INITIAL_PARAMETER_ITEM_CATEGORY]: null,
            [Constants.INITIAL_PARAMETER_EC_LIST_NAME]: null,
            [Constants.INITIAL_PARAMETER_EC_LIST_CODE]: null,
            [Constants.INITIAL_PARAMETER_EC_LIST_POSITION]: null,
            [Constants.INITIAL_PARAMETER_EC_LIST_PAGE]: null,
            [Constants.INITIAL_PARAMETER_LOCALE]: null
        };
    }


    initialize () {
        this.setUrlParameters();
    }


    setUrlParameters () {
        const currentUrl = new Url();
        const allowedParameterNames = Object.keys(_.result(this, 'defaults'));
        const queryParameters = _.pick(currentUrl.queryParameters, allowedParameterNames);
        this.thirdPartyParams = _.omit(currentUrl.queryParameters, allowedParameterNames);
        this.thirdPartyParamsString = Object.keys(this.thirdPartyParams)
            .map(key => `${key}=${this.thirdPartyParams[key]}`)
            .join('&');
        this.allQueryParameters = currentUrl.queryParameters;
        this.set(queryParameters);
    }
}
