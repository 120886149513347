import React, { useState, useRef, useEffect } from 'react';
import app from '../../app/app';
import OverlayView from '../overlay';
import CabinVipView from './cabin-vip';
import CabinNumberView from '../cabinnumber';
import * as Constants from '../../config/constants';
import StringUtil from '../../util/string';
import SVGFile from './cabin-svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { CabinPrice } from './cabin-price';

type CabinCategoryProps = {
    stepModelCabinCategory: any;
    cabinIndex: number;
    hasSelectedInclusiveVIP: boolean;
    cabinCategoryTypeCodeValue: string;
    stepModelCabinDeck: any;
    stepModelCabinSelection: any;
    stepModelFlow: any;
    callback: Function;
    searchSubmit: Function;
    apiError: boolean;
};

type BookingStatusType = {
    trip: {
        get: Function;
    } | any;
} | null;

type StepDataType = {id: string, content:{}};

export default function CabinCategoryView (props: CabinCategoryProps) {
    const { stepModelCabinCategory, stepModelCabinDeck, stepModelCabinSelection, cabinIndex, hasSelectedInclusiveVIP, cabinCategoryTypeCodeValue, stepModelFlow, callback, searchSubmit, apiError} = props;

    const bookingStatus: BookingStatusType = app?.apiSession?.bookingStatus || null;
    const shipCode = bookingStatus?.trip.get('shipCode') || null;

    const thisInput = stepModelCabinCategory.inputs.get(Constants.NETMATCH_INPUT_CABINCATEGORY_CATEGORYCODE);
    const thisInputOptions = thisInput.get('options');
    const thisInputValue = thisInput.get('inputValue');

    const [open, setOpen] = useState(!thisInputValue);
    const [refresh, setRefresh] = useState(false);

    if (apiError && !open) {
        setOpen(true);
    }

    useEffect(() => {
        if (apiError) {
            setOpen(true);
        } else {
            setOpen(!thisInputValue);
        }
    }, [thisInputValue, stepModelCabinCategory, apiError]);


    const toSubmitStep = (stepData:Array<StepDataType>) => {
        setOpen(false);
        callback(stepData);
    };

    const setRefreshRender = () => {
        setRefresh(!refresh);
    };

    let cabinCategoryList;
    if (thisInputOptions) {
        cabinCategoryList = prepareCategoryList(thisInputOptions, thisInputValue, cabinCategoryTypeCodeValue);
    }
    const apiSteps:any = (app.apiSession) ? app.apiSession.steps : null;
    const content = app.contentApi;
    const cabinCategoryText:any = content ? content.messages.prepareMessages('cabinCategory') : null;

    return (
        <div className="cabin-catergory">{refresh}
            <span className="scroll-point-cabin-catergory-s"></span>
            <h2 className="api-step-headline expand">
                <FormattedMessage id="components.cabin.cabin-category.headline" />
                {thisInputValue ?
                    <div className="cabin-is-expand" onClick={() => {
                        setOpen(!open);
                        //  setShortOpen(!open);
                        if (app && app.trackController && !open) {
                            app.trackController.eventTracking({
                                action: 'Kabinenwahl_Kategorie',
                                label: 'Auswahl ändern',
                                ga4clickLabel: 'content.custom.kategorie-aendern'
                            });
                        }
                    }}>
                        {!open
                            ? (
                                <FormattedMessage id="general.change-selection" />
                            )
                            : (
                                <FormattedMessage id="general.close" />
                            )} <span className={open ? 'icon-ic-edit' : ''}> </span>
                    </div>
                    : ''}
            </h2>
            <div className={`inner-cc ${!open ? 'show' : 'hidden-anim'}`}>
                <SelectedView
                    list={cabinCategoryList}
                    shipCode={shipCode}
                    cabinIndex={cabinIndex}
                    callback={toSubmitStep}
                    apiError={apiError}
                    refreshCallback = {setRefreshRender}
                    searchSubmit = {searchSubmit}
                    cabinCategoryText={cabinCategoryText}
                    stepModelFlow={stepModelFlow}
                    stepModelCabinCategory={stepModelCabinCategory}
                    stepModelCabinDeck={stepModelCabinDeck}
                    stepModelCabinSelection={stepModelCabinSelection}></SelectedView>
            </div>
            <div className={`inner-cc ${open ? 'show' : 'hidden-anim'}`}>
                <AllPartView list={cabinCategoryList} shipCode={shipCode} callback={toSubmitStep} cabinCategoryText={cabinCategoryText} stepModel={stepModelCabinCategory}></AllPartView>
            </div>

            {apiSteps.get(`${Constants.NETMATCH_STEP_CABINVIP_PURE}${cabinIndex}`) &&
            !hasSelectedInclusiveVIP &&
            (apiSteps.get(`${Constants.NETMATCH_STEP_CABINVIP_PURE}${cabinIndex}`).isSomehowCompleted() || apiSteps.get(`${Constants.NETMATCH_STEP_CABINVIP_PURE}${cabinIndex}`).isOpen()) ?
                <CabinVipView
                    stepModelVip={apiSteps.get(`${Constants.NETMATCH_STEP_CABINVIP_PURE}${cabinIndex}`)}
                    callback={callback}></CabinVipView>
                : ''}
        </div>
    );
}


type SelectedViewProps = {
    list: any;
    shipCode: string;
    cabinIndex: number;
    cabinCategoryText: any;
    stepModelCabinCategory:any;
    stepModelCabinDeck: any;
    stepModelCabinSelection: any;
    stepModelFlow: any;
    callback: Function;
    searchSubmit: Function;
    apiError:boolean;
    refreshCallback: Function;
}

const SelectedView = (props: SelectedViewProps) => {
    const { formatMessage, formatNumber } = useIntl();
    const { list, shipCode, cabinIndex, cabinCategoryText, stepModelCabinCategory, stepModelCabinDeck, stepModelCabinSelection, stepModelFlow, callback, searchSubmit, apiError, refreshCallback } = props;
    // const [open, setOpen] = useState(true);
    const thisFlow = stepModelFlow.inputs.get(Constants.NETMATCH_INPUT_CABINFLOW);
    const thisFlowValue = thisFlow ? thisFlow.get('inputValue') : null;
    const thisFlowStatus = thisFlow ? stepModelFlow.status : null;

    const thisCabinCategory = stepModelCabinCategory.inputs.get(Constants.NETMATCH_INPUT_CABINCATEGORY_CATEGORYCODE);
    const thisCabinCategoryValue = thisCabinCategory ? thisCabinCategory.get('inputValue') : null;

    const [open, setOpen] = useState(thisFlowStatus === Constants.NETMATCH_STEP_STATUS_OPEN);

    const flowData:any = prepareFlowOptions(stepModelFlow);
    // console.log(open, '_', thisFlowStatus, '_', stepModelCabinSelection.status, '_', thisCabinCategoryValue);
    if (apiError && !open) {
        setOpen(true);
    }

    useEffect(() => {
        if (apiError) {
            setOpen(true);
        } else {
            if (stepModelCabinSelection && stepModelCabinSelection.status === Constants.NETMATCH_STEP_STATUS_OPEN) {
                setOpen(true);
            } else {
                setOpen(thisFlowStatus === Constants.NETMATCH_STEP_STATUS_OPEN);
            }
        }
    }, [thisFlowStatus, stepModelCabinSelection, thisCabinCategoryValue, apiError]);

    /* let webpAddOn = '';
    if (app.canWebp) {
        webpAddOn = '&format=webp';
    } */

    const toSubmitStep = (stepData:Array<StepDataType>) => {
        setOpen(false);
        callback(stepData);
    };

    let closeTextAddon = '';
    if (thisFlowValue) {
        if (thisFlowValue === Constants.NETMATCH_OPTION_CABINFLOW_CABINNUMBER) {
            const thisDeckInput = stepModelCabinDeck.inputs.get(Constants.NETMATCH_INPUT_CABINDECK_DECKNUMBER);
            const thisDeckInputValue = thisDeckInput ? thisDeckInput.get('inputValue') : '';
            const thisNumberInput = stepModelCabinSelection.inputs.get(Constants.NETMATCH_INPUT_CABINNUMBER_CABINNUMBER);
            const thisNumberInputValue = thisNumberInput ? thisNumberInput.get('inputValue') : '';
            if (thisNumberInputValue && thisDeckInputValue) {
                closeTextAddon = formatMessage({
                    id: 'components.cabin.cabin-category.pick.value'
                }, { deck: thisDeckInputValue, cabin: thisNumberInputValue });
            }
        } else if (thisFlowValue === Constants.NETMATCH_OPTION_CABINFLOW_CATEGORY_ONLY) {
            closeTextAddon = formatMessage({
                id: 'components.cabin.cabin-category.pick.automatically'
            });
        }
    }

    return (
        <div className="list">
            {list.map((value: any, key: number) => {
                if (value.selected && value.selectedValue === value.id) {
                    return (
                        <div key={key} className={`part-cc ${value.selected ? 'selected' : ''}`}>
                            <div className="category">
                                <span className="bold">{value.displayText}</span> {!open ? `${closeTextAddon}` : ''}
                                <span className={`arrow ${open ? 'open' : ''}`} onClick={() => {
                                    setOpen(!open);

                                    if (app && app.trackController) {
                                        app.trackController.eventTracking({
                                            action: 'Kabinenwahl_Kategorie',
                                            label: `${open ? 'Einklappen' : 'Ausklappen'}`,
                                            ga4clickLabel: `content.icon.kategorie-${open ? 'Einklappen' : 'Ausklappen'}`
                                        });
                                    }
                                }}></span>
                            </div>
                            <div className="image">
                                <SVGFile highlight={value.id} shipCode={shipCode}></SVGFile>
                            </div>
                            <div className={`inner-cc ${open ? 'show' : 'hidden-anim'}`}>
                                <div className="notifications">
                                    {value.content && value.content.decks && value.content.decks.length !== 0 ?
                                        <div className="deck">
                                            <div className="bold">{cabinCategoryText ? cabinCategoryText.decksPerCategory : ''}</div>
                                            {value.content.decks.map((deck: any, deckKey: number) => {
                                                return (
                                                    <div key={deckKey} className="deck-list">
                                                        <FormattedMessage id="general.cabin.location" values={{
                                                            cabinType: value.cabinTypeName,
                                                            deck
                                                        }} />
                                                    </div>);
                                            })}
                                        </div>
                                        : ''}
                                    {value.content && value.content.cabinCategoryEquipments && value.content.cabinCategoryEquipments.length !== 0 ?
                                        <div className="special">
                                            <div className="bold">
                                                <FormattedMessage id="general.specials" />
                                            </div>
                                            {value.content.cabinCategoryEquipments.map((eq: any, eqkKey: number) => {
                                                return (
                                                    <div key={eqkKey} className="eq-list">
                                                        {eq.iconURL ? <img className="eq-icon"src={`${eq.iconURL}`}/> : ''}
                                                        <span className="text">{eq.value}</span>
                                                    </div>);
                                            })}
                                        </div>
                                        : ''}
                                </div>
                                <div className="cabin-price-cta-wrapper ">
                                    <span className="nobr">
                                        <CabinPrice price={value.price} mode="from_pp" />
                                    </span>
                                    <span className="cabin-selected">
                                        <FormattedMessage id="general.selected" />
                                    </span>
                                </div>

                                <div className="cabin-number-deck">
                                    {flowData[Constants.NETMATCH_OPTION_CABINFLOW_CABINNUMBER] ?
                                        <CabinNumberDeckView
                                            detail={value}
                                            cabinIndex={cabinIndex}
                                            stepModelFlow={stepModelFlow}
                                            stepModelCabinCategory={stepModelCabinCategory}
                                            stepModelCabinDeck={stepModelCabinDeck}
                                            stepModelCabinSelection={stepModelCabinSelection}
                                            refreshCallback={refreshCallback}
                                            searchSubmit={searchSubmit}></CabinNumberDeckView>
                                        : ''}
                                    {flowData[Constants.NETMATCH_OPTION_CABINFLOW_CATEGORY_ONLY] ?
                                        <TuicChoiseView value={value.id} cabinCategoryText={cabinCategoryText} stepModelFlow={stepModelFlow} callback={toSubmitStep}></TuicChoiseView>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return null;
                }
            })}
        </div>
    );
};

type CabinNumberDeckViewProps = {
    detail:any;
    cabinIndex:number;
    stepModelCabinCategory:any;
    stepModelCabinDeck: any;
    stepModelCabinSelection: any;
    stepModelFlow:any;
    refreshCallback: Function;
    searchSubmit: Function;
}

const CabinNumberDeckView = (props: CabinNumberDeckViewProps) => {
    // const [refresh, setRefresh] = useState(false);
    const {detail, cabinIndex, stepModelCabinCategory, stepModelCabinDeck, stepModelCabinSelection, stepModelFlow, refreshCallback, searchSubmit } = props;

    const flowInputs = stepModelFlow.inputs.get(Constants.NETMATCH_INPUT_CABINFLOW);
    const flowValue = flowInputs.get('inputValue');

    const thisDeckInput = stepModelCabinDeck.inputs.get(Constants.NETMATCH_INPUT_CABINDECK_DECKNUMBER);
    const thisDeckInputValue = thisDeckInput ? thisDeckInput.get('inputValue') : '';

    const thisNumberInput = stepModelCabinSelection.inputs.get(Constants.NETMATCH_INPUT_CABINNUMBER_CABINNUMBER);
    const thisNumberInputValue = thisNumberInput ? thisNumberInput.get('inputValue') : '';

    const cabinNumberRef = useRef();
    const onCabinNumberOverlay = () => {
        // @ts-ignore
        if (cabinNumberRef.current && cabinNumberRef.current.showModal === false) {
            // @ts-ignore
            cabinNumberRef.current.handleOpenModal();
        }
    };

    return (
        <div className="part-cc cabin-number">
            {thisNumberInputValue ?
                <span>
                    <div className="text selected">
                        <FormattedMessage id="components.cabin.cabin-category.pick.value" values={{
                            deck: thisDeckInputValue,
                            cabin: thisNumberInputValue
                        }} />
                    </div>
                    <div className="text underline CabinFlow-change" onClick={() => {
                        onCabinNumberOverlay();
                        if (app && app.trackController) {
                            app.trackController.eventTracking({
                                action: 'Kabinenwahl_Kategorie',
                                label: 'Wunschkabine ändern',
                                ga4clickLabel: 'content.custom.wunschkabine-aendern'
                            });
                        }
                    }}>
                        <FormattedMessage id="components.cabin.cabin-category.pick.manually.cta" />
                    </div>
                </span>
                :
                <div className="text">
                    <FormattedMessage id="components.cabin.cabin-category.pick.manually.text" />
                </div>
            }

            {flowValue === Constants.NETMATCH_OPTION_CABINFLOW_CABINNUMBER && thisNumberInputValue ?
                <div className="cabin-price-cta-wrapper">
                    <span className="cabin-selected">
                        <FormattedMessage id="general.selected" />
                    </span>
                </div>
                :
                <a className={`button cnd-available CabinFlow-${detail.id}-${thisDeckInputValue}`}
                    onClick={() => {
                        onCabinNumberOverlay();
                        // toSubmitStepCabinNumberDeck(thisNumberInputValue, thisDeckInputValue);

                        if (app && app.trackController) {
                            app.trackController.eventTracking({
                                action: 'Kabinenwahl_Kategorie',
                                label: 'CTA_Kabine auswählen',
                                ga4clickLabel: 'content.button.kategorie-kabine-auswaehlen'
                            });
                        }
                    }} >
                    <FormattedMessage id="general.cabin.select" />
                </a>
            }
            <CabinNumberView
                hasBackButton={true}
                ref={cabinNumberRef}
                cabinIndex={cabinIndex}
                detail={detail}
                stepModelFlow={stepModelFlow}
                stepModelCabinCategory= {stepModelCabinCategory}
                stepModelCabinDeck={stepModelCabinDeck}
                stepModelCabinSelection={stepModelCabinSelection}
                refreshCallback={refreshCallback}
                searchSubmit={searchSubmit}></CabinNumberView>
        </div>
    );
};

type TuicChoiseViewProps = {
    value: string;
    cabinCategoryText: any;
    stepModelFlow: any;
    callback: Function;
};

const TuicChoiseView = (props: TuicChoiseViewProps) => {
    const { value, cabinCategoryText, stepModelFlow, callback } = props;


    const toSubmitStepCategoryOnly = () => {
        const stepsToSubmit = [];
        const stepData:StepDataType = {
            'id': stepModelFlow.get('id'),
            'content': {
                [Constants.NETMATCH_INPUT_CABINFLOW]: Constants.NETMATCH_OPTION_CABINFLOW_CATEGORY_ONLY
            }
        };
        if (stepModelFlow && stepModelFlow.validateData(stepData).valid) {
            stepsToSubmit.push(stepData);
            callback(stepsToSubmit);
        }
    };

    const overlayRef = useRef();
    const onBenefitOverlay = () => {
        // @ts-ignore
        if (overlayRef.current && overlayRef.current.showModal === false) {
            // @ts-ignore
            overlayRef.current.handleOpenModal();

            if (app && app.trackController) {
                app.trackController.eventTracking({
                    action: 'Kabinenwahl_Kategorie',
                    label: 'Weitere Informationen',
                    ga4clickLabel: 'content.icon.kategorie-weitere-informationen'
                });
            }
        }
    };

    const flowInputs = stepModelFlow.inputs.get(Constants.NETMATCH_INPUT_CABINFLOW);
    const flowValue = flowInputs.get('inputValue');

    return (
        <div className="part-cc">
            <div className="text">{cabinCategoryText ? cabinCategoryText.textCategorySelection : ''}</div>
            <div className="tuic-choise">
                <div className="hint help-hint benefit-hint"><a onClick={onBenefitOverlay}>{cabinCategoryText ? cabinCategoryText.advantagesCategorySelection : ''}</a></div>
                <OverlayView overlayId={'benefit'} closeOnBgClick={true} hasBackButton={true} data={null} ref={overlayRef}></OverlayView>
                {flowValue === Constants.NETMATCH_OPTION_CABINFLOW_CATEGORY_ONLY ?
                    <div className="cabin-price-cta-wrapper ">
                        <span className="cabin-selected">
                            <FormattedMessage id="general.selected" />
                        </span>
                    </div>
                    :
                    <a className={`button available CabinFlow-${value}`}
                        onClick={() => {
                            toSubmitStepCategoryOnly();

                            if (app && app.trackController) {
                                app.trackController.eventTracking({
                                    action: 'Kabinenwahl_Kategorie',
                                    label: 'CTA_Kategorie auswählen',
                                    ga4clickLabel: 'content.button.kategorie-kategorie-auswaehlen'
                                });
                            }
                        }} >
                        <FormattedMessage id="components.cabin.cabin-category.category" />
                    </a>}
            </div>
        </div>
    );
};

type AllPartViewProps = {
    list: any;
    shipCode: string;
    cabinCategoryText: any;
    stepModel:any;
    callback: Function;
};

const AllPartView = (props: AllPartViewProps) => {
    const { formatNumber } = useIntl();
    const { list, shipCode, cabinCategoryText, stepModel, callback } = props;

    const toSubmitStepCabinCategorie = (categoryCode:string) => {
        const stepsToSubmit = [];
        const stepData: StepDataType = {
            'id': stepModel.get('id'),
            'content': {
                [Constants.NETMATCH_INPUT_CABINCATEGORY_CATEGORYCODE]: categoryCode
            }
        };
        console.log(stepData);

        if (stepModel && stepModel.validateData(stepData).valid) {
            stepsToSubmit.push(stepData);
            callback(stepsToSubmit);
        }
    };

    return (
        <div className="list">
            {list.length > 1 && cabinCategoryText ? <div className="list-intro">{cabinCategoryText.subline}</div> : ''}
            {list.map((value: any, key: number) => {
                return (
                    <div key={key} className={`part-cc ${value.selected ? 'selected' : ''}`}>
                        <div className="bold">{value.displayText}</div>
                        <div className="image">
                            <SVGFile highlight={value.id} shipCode={shipCode}></SVGFile>
                        </div>
                        <div className="notifications">
                            {value.content && value.content.decks && value.content.decks.length !== 0 ?
                                <div className="deck">
                                    <div className="bold">{cabinCategoryText ? cabinCategoryText.decksPerCategory : ''}</div>
                                    {value.content.decks.map((deck: any, deckKey: number) => {
                                        return (
                                            <div key={deckKey} className="deck-list">
                                                <FormattedMessage id="general.cabin.location" values={{
                                                    cabinType: value.cabinTypeName,
                                                    deck
                                                }} />
                                            </div>);
                                    })}
                                </div>
                                : ''}
                            {value.content && value.content.cabinCategoryEquipments && value.content.cabinCategoryEquipments.length !== 0 ?
                                <div className="special">
                                    <div className="bold">
                                        <FormattedMessage id="general.specials" />
                                    </div>
                                    {value.content.cabinCategoryEquipments.map((eq: any, eqkKey: number) => {
                                        return (
                                            <div key={eqkKey} className="eq-list">
                                                {eq.iconURL ? <img className="eq-icon" src={`${eq.iconURL}`}/> : ''}
                                                <span className="text">{eq.value}</span>
                                            </div>);
                                    })}
                                </div>
                                : ''}
                        </div>
                        <div className="cabin-price-cta-wrapper ">
                            <span className="nobr">
                                <CabinPrice price={value.price} mode="from_pp" />
                            </span>
                            {value.selected && value.selectedValue === value.id ?
                                <span className="cabin-selected">
                                    <FormattedMessage id="general.selected" />
                                </span>
                                :
                                <a className={`button cabin-available typecode-${value.id}`}
                                    onClick={() => {
                                        toSubmitStepCabinCategorie(value.id);
                                        if (app && app.trackController) {
                                            app.trackController.eventTracking({
                                                action: 'Kabinenwahl_Kategorie',
                                                label: value.displayText,
                                                ga4clickLabel: `content.button.kategorie-auswaehlen-${value.displayText}`
                                            });
                                        }
                                    }} >
                                    <FormattedMessage id="components.cabin.cabin-category.category" />
                                </a>
                            }
                        </div>
                    </div>
                );
            })}
        </div>
    );
};


const prepareCategoryList = (thisInputOptions:Array<any>, thisInputValue:any, cabinCategoryTypeCodeValue: string) => {
    const categoryList:any = [];
    let contentCI: any = {};
    if (thisInputOptions && app.contentApi) {
        const content = app.contentApi.cabinTypesAndCategories.getCabinCabinCategories() || [];
        contentCI = content.find((item:any) => item.categoryTypeCode === cabinCategoryTypeCodeValue);
    }

    thisInputOptions.forEach((item:any) => {
        if (!item.isBlocked && !item.isBlocke === true) {
            const advanced = {
                ...item,
                content: contentCI.cabinCategories.find((part:any) => part.cabinCategoryCode === item.id),
                cabinTypeName: contentCI.cabinTypeName,
                selected: false,
                smallView: false,
                selectedValue: null
            };
            if (thisInputValue) {
                if (item.id === thisInputValue) {
                    advanced.selected = true;
                    advanced.smallView = true;
                    advanced.selectedValue = thisInputValue;
                }
                // console.log(items.filter((cabin:any) => cabin.id === thisInputValue));
            }
            categoryList.push(advanced);
        }
    });

    return categoryList;
};

const prepareFlowOptions = (raw:any) => {
    type FlowType = Array<any>;
    const flow:FlowType = [];

    const flowInputs = raw.inputs.get(Constants.NETMATCH_INPUT_CABINFLOW);
    const flowOptions = flowInputs.get('options') || [];

    flowOptions.forEach((value:any) => {
        // console.log('%c flowOptions ', 'background: #ff0021; color: #000000', value);
        if (value.isBlocked === false) {
            flow[value.id] = true;
        }
    });
    return flow;
};
