import _ from 'underscore';
import Backbone from 'backbone';
import app from '../app/app';
import config from '../config/config';
import Cookie from '../util/cookie';
import * as Constants from '../config/constants';
import {NETMATCH_INPUT_LANGUAGE_CODE, NETMATCH_ONLINE_RISK_BLACKLISTED_TRAVELER, NETMATCH_ONLINE_RISK_NO_BOOKING_BLACKLISTED, NETMATCH_ONLINE_RISK_NO_BOOKING_NEGATIVE_CREDIT, NETMATCH_ONLINE_RISK_NO_BOOKING_UNKNOWN_REASON, NETMATCH_ONLINE_RISK_NO_DIRECT_DEBIT, NETMATCH_ONLINE_RISK_NO_RISK, NETMATCH_ONLINE_RISK_UNKNOWN, PAGE_OVERVIEW} from '../config/constants';
import IbeStorageModel from '../entities/ibe/model/storage';
import RequestParametersModel from '../entities/ibe/model/request-parameters';
import IbeConfigModel from '../entities/config';
import IbeHealthcheckModel from '../entities/healthcheck';
import InactivityWatcher from '../item/inactivity-watcher';
import {ApiSessionSetupError, ApiStepSubmitError, ContentApiError} from '../error/errors';
import OnlineRiskStepModel from '../entities/netmatch-api/model/onlineRiskStep';
import {getLocaleAPI} from '../intl';

class IBEController {
    /**
     *
     */
    constructor () {
        _.extend(this, Backbone.Events);
        _.bindAll(this, 'initIbeStorage', 'initApiSession', 'restoreBookingState', 'setRequestParameters', 'ensureTripCode', 'initContentApi', 'initializationReady', 'appBackLink');

        this.ibeInited = false;
        this.papagenaId = false;
        this.cartRecoveryFlag = false;
        this.specialMediaRouteURL = false;
        this.B2BinfonetBackURL = Constants.TUIC_B2B_BACK_URL_FALLBACK;
        this.B2BinfonetAuthURL = Constants.TUIC_B2B_AUTH_URL_FALLBACK;
        this.ibeStatus = {
            netMatchApi: true, ensureTripCode: true, contentApi: true, restoreBooking: false, healthcheckDetail: [], httpStatus: '', httpStatusText: '', mainErrorSessionId: null, apiUrl: ''
        };
    }


    /**
     *
     */
    initializationReady () {
        this.ibeInited = true;

        const currentPage = app.router.getCurrentFragment();
        let nextPage = currentPage;
        if (!currentPage || currentPage === 'index') {
            const firstBookingPage = app.bookingPages.first();
            if (firstBookingPage) {
                nextPage = firstBookingPage.id;
            }
        }
        if (currentPage !== nextPage) {
            const thirdPartyParams = this.initialRequestParams.thirdPartyParamsString ? `?${this.initialRequestParams.thirdPartyParamsString}` : '';
            app.router.redirect(`${nextPage}${thirdPartyParams}`);
        } else {
            this.requestPage(nextPage);
        }

        InactivityWatcher.init(config.inactivityTimeout);
    }


    /**
     *
     */
    initIbe () {
        this.initialRequestParams = new RequestParametersModel();

        return this.initIbeStorage()
            .then(this.initConfig)
            .then(this.restoreBookingState)
            .then(this.initApiSession)
            .then(this.setRequestParameters)
            .then(this.ensureTripCode)
            .then(this.initContentApi)
            .then(this.setB2BSpecials)
            .then(this.initializationReady)
            .catch((error) => {
                console.warn('IBE Conroller init error:', error);
                if (error instanceof ApiSessionSetupError) {
                    console.error(error);
                } else if (error instanceof ApiStepSubmitError) {
                    console.error(error);
                    app.ibeController.ibeStatus.netMatchApi = false;
                }

                if (error instanceof ContentApiError) {
                    app.ibeController.ibeStatus.contentApi = false;
                }
                this.requestPage('error503');
            });
    }


    /**
     *
     */
    initIbeStorage () {
        this.ibeStorage = new IbeStorageModel();
        return this.ibeStorage.init();
    }


    initConfig () {
        const ibeConfig = new IbeConfigModel();
        return new Promise((resolve, reject) => {
            ibeConfig.loadConfig()
                .then((item) => {
                    app.trackController.init(item.tracking);
                    app.ibeController.setPapagenaId(item.papagenaId);
                    // importent for 'DeckPlan' View
                    app.ibeController.setSpecialMediaRouteURL(item.mediaRouteURL);

                    if (item.showCancelPopUp) {
                        // TUICIBE-159 - confirm browser close
                        window.addEventListener('beforeunload', app.ibeController.onBeforeUnloadFunction, false);
                    }


                    // TUICMA-5
                    if (item.cartRecovery) {
                        app.ibeController.setCartRecoveryFlag(item.cartRecovery);
                    }

                    // TUICUNIT-2903
                    if (item.infonet) {
                        app.ibeController.setB2BinfonetURLs(item.infonet);
                    }

                    if (item.healthcheck && item.healthcheck.enabled) {
                        // app.ibeController.ibeHealthcheckAPI(item.healthcheck.status, item.healthcheck.topics);
                    }

                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * TUICIBE-159 - confirm browser close Pop-up
     */
    onBeforeUnloadFunction (e) {
        const evnt = e || window.e;
        // most browsers will display a generic text instead of this message
        const dialogText = 'Möchten Sie diese Website verlassen? Von Ihnen vorgenommene Eingaben und Änderungen werden möglicherweise nicht gespeichert.';
        if (evnt) {
            evnt.returnValue = dialogText;
        }
        return dialogText;
    }

    setPapagenaId (value) {
        this.papagenaId = value;
    }

    getPapagenaId () {
        return (this.papagenaId);
    }


    setSpecialMediaRouteURL (value) {
        const urlRegex = '^(https?://)?([da-z.-]+).([a-z.]{2,6})([/w .-]*)*/?$';
        const regEx = new RegExp(urlRegex);
        if (regEx.test(value)) {
            this.specialMediaRouteURL = value;
        }
    }


    getSpecialMediaRouteURL () {
        return (this.specialMediaRouteURL);
    }

    // TUICUNIT-2903
    setB2BinfonetURLs (value) {
        if (value?.backURL) {
            this.B2BinfonetBackURL = value.backURL;
        }
        if (value?.authURL) {
            this.B2BinfonetAuthURL = value.authURL;
        }
    }

    getB2BinfonetBackURL () {
        return (this.B2BinfonetBackURL);
    }

    getB2BinfonetAuthURL () {
        return (this.B2BinfonetAuthURL);
    }

    // end: TUICUNIT-2903

    /**
     *
     */
    initApiSession () {
        this.isSessionValid();

        const sessionId = this.ibeStorage.get(Constants.NETMATCH_API_SESSION_ID_KEY);
        return new Promise((resolve, reject) => {
            app.apiSession
                .setup(sessionId, true)
                .then(() => {
                    console.log('%c IBE initApiSession ', 'background: #222; color: #ffff00', app.apiSession.get('id'));
                    resolve(this.ibeStorage.saveApiSessionId(app.apiSession.get('id')));
                })
                .catch((error) => {
                    console.log('%c IBE initApiSession: catch ', 'background: #222; color: #ffff00', app.apiSession);
                    app.ibeController.ibeStatus.netMatchApi = false;
                    reject(error);
                });
        });
    }


    isSessionValid () {
        const urlParams = this.initialRequestParams;
        if (urlParams.get(Constants.INITIAL_PARAMETER_TRIPCODE) && urlParams.get(Constants.INITIAL_PARAMETER_EPACKAGECODE)) {
            const initialParameter = app.ibeController.ibeStorage.get(Constants.INITIAL_PARAMETER) ? app.ibeController.ibeStorage.get(Constants.INITIAL_PARAMETER) : null;
            if (initialParameter === null) {
                this.ibeStorage.saveInitialParameter(urlParams.get(Constants.INITIAL_PARAMETER_TRIPCODE), urlParams.get(Constants.INITIAL_PARAMETER_EPACKAGECODE));
            } else {
                const isInitialTripcode = (initialParameter[Constants.INITIAL_PARAMETER_TRIPCODE] === urlParams.get(Constants.INITIAL_PARAMETER_TRIPCODE));
                const isInitialEPackageCode = (initialParameter[Constants.INITIAL_PARAMETER_EPACKAGECODE] === urlParams.get(Constants.INITIAL_PARAMETER_EPACKAGECODE));
                if (isInitialTripcode === false || isInitialEPackageCode === false) {
                    // console.log('mach alles neu');
                    this.ibeStorage.deleteStorage();
                    this.ibeStorage.saveInitialParameter(urlParams.get(Constants.INITIAL_PARAMETER_TRIPCODE), urlParams.get(Constants.INITIAL_PARAMETER_EPACKAGECODE));
                }
            }
        }
    }

    /**
     * TUICMA-5: real special case, set resort Flag, tried to clean personal data and restore the booking state.
     * This resort Flag is needed in requestPage() and in Error503Page
     * If success then set local storage and clean url
     * If error clean url and storage
     **/

    setCartRecoveryFlag (value) {
        this.cartRecoveryFlag = value;
    }

    getCartRecoveryFlag () {
        return (this.cartRecoveryFlag);
    }

    restoreBookingState () {
        const urlParams = this.initialRequestParams;
        if (urlParams.get(Constants.INITIAL_PARAMETER_API_SID) && urlParams.get(Constants.INITIAL_PARAMETER_TRIPCODE) && urlParams.get(Constants.INITIAL_PARAMETER_EPACKAGECODE) && app.ibeController.getCartRecoveryFlag() === true) {
            app.ibeController.ibeStatus.restoreBooking = true;
            // TUICUNIT-2311: Recovery Tracking
            const thirdPartyParams = urlParams.thirdPartyParamsString ? `?${urlParams.thirdPartyParamsString}` : '';
            if (thirdPartyParams !== '') {
                app.router.navigate(`${app.router.getCurrentFragment()}${thirdPartyParams}`, {
                    trigger: false, replace: true
                });
                if (urlParams.thirdPartyParams) {
                    app.trackController.setRecoveryTracking(urlParams.thirdPartyParams);
                }
            }

            return app.apiSession.deleteStepsIfYouKnowWhatYouDo(Constants.RESTOREBOOKING_RESET_NETMATCH_STEPS, false, urlParams.get(Constants.INITIAL_PARAMETER_API_SID))
                .then(() => {
                    app.router.navigate(app.router.getCurrentFragment(), {
                        trigger: false, replace: true
                    });
                    app.ibeController.ibeStorage.deleteStorage();
                    app.ibeController.ibeStorage.saveInitialParameter(urlParams.get(Constants.INITIAL_PARAMETER_TRIPCODE), urlParams.get(Constants.INITIAL_PARAMETER_EPACKAGECODE));
                    app.ibeController.ibeStorage.saveApiSessionId(urlParams.get(Constants.INITIAL_PARAMETER_API_SID));
                    // store Info that this comes from a restore Link
                    app.ibeController.ibeStorage.save('restoreBookingState', 'true');
                })
                .catch((error) => {
                    app.router.navigate(app.router.getCurrentFragment(), {
                        trigger: false, replace: true
                    });
                    app.ibeController.ibeStorage.deleteStorage();
                    return Promise.reject(error);
                });
        }
        return Promise.resolve();
    }


    /**
     *  TUICIBE-610
     */
    ibeHealthcheckAPI (statusLevel, checkTopics) {
        const ibeHealthcheck = new IbeHealthcheckModel();
        ibeHealthcheck.getStatus(statusLevel, checkTopics)
            .then((value) => {
                if (value.length !== 0) {
                    app.ibeController.ibeStatus.healthcheckDetail = value;
                }
            })
            .catch(() => {
            });
    }


    /**
     *
     */
    setRequestParameters () {
        const urlParams = this.initialRequestParams;

        // clear #hash from url
        if (document.location.hash) {
            history.replaceState(null, null, ' ');
        }

        // remove the get parameters
        const thirdPartyParams = this.initialRequestParams.thirdPartyParamsString ? `?${this.initialRequestParams.thirdPartyParamsString}` : '';
        app.router.navigate(`${app.router.getCurrentFragment()}${thirdPartyParams}`, {
            trigger: false, replace: true
        });

        // TUICUNIT-278
        if (urlParams.thirdPartyParams && urlParams.thirdPartyParams[Constants.NATIVE_APP_TRIGGER_PARAMETER] && urlParams.thirdPartyParams[Constants.NATIVE_APP_TRIGGER_PARAMETER] === '1') {
            app.trackController.setInAppTracking();
        }

        const stepsToSubmit = [];
        let stepData;
        let step;

        // do not change the booking when the approval was done
        const approvalStep = app.apiSession.steps.get(Constants.NETMATCH_STEP_APPROVAL);
        if (approvalStep && approvalStep.isDone()) {
            return Promise.resolve();
        }

        // set app Backlink
        if (urlParams.get(Constants.INITIAL_PARAMETER_BACK_LINK)) {
            this.ibeStorage.saveAppBackLink(urlParams.get(Constants.INITIAL_PARAMETER_BACK_LINK));
        }

        // set Tracking Params
        if (urlParams.get(Constants.INITIAL_PARAMETER_ITEM_CATEGORY) || urlParams.get(Constants.INITIAL_PARAMETER_EC_LIST_NAME) || urlParams.get(Constants.INITIAL_PARAMETER_EC_LIST_CODE) || urlParams.get(Constants.INITIAL_PARAMETER_EC_LIST_POSITION) || urlParams.get(Constants.INITIAL_PARAMETER_EC_LIST_PAGE)) {
            this.ibeStorage.saveTrackingParams(urlParams.get(Constants.INITIAL_PARAMETER_ITEM_CATEGORY) || '', urlParams.get(Constants.INITIAL_PARAMETER_EC_LIST_NAME) || '', urlParams.get(Constants.INITIAL_PARAMETER_EC_LIST_CODE) || '', urlParams.get(Constants.INITIAL_PARAMETER_EC_LIST_POSITION) || '', urlParams.get(Constants.INITIAL_PARAMETER_EC_LIST_PAGE) || '');
        }

        if (urlParams.get(Constants.INITIAL_PARAMETER_TRIPCODE) && Object.keys(urlParams.allQueryParameters).length !== 0) {
            const value = Object.keys(urlParams.allQueryParameters)
                .map(key => `${key}=${urlParams.allQueryParameters[key]}`)
                .join('&');
            this.ibeStorage.saveAllQueryParameters(value);
        }

        const languageStep = app.apiSession.steps.get(Constants.NETMATCH_STEP_LANGUAGE);
        let hadToSetLanguage = false;
        if (languageStep) {
            // language submitted but might differ so we need to check for refetching purposes
            const languageInput = languageStep.inputs.get(NETMATCH_INPUT_LANGUAGE_CODE);
            if (languageInput.attributes.inputValue !== getLocaleAPI().toUpperCase()) {
                stepData = {
                    'id': Constants.NETMATCH_STEP_LANGUAGE,
                    'content': {
                        [Constants.NETMATCH_INPUT_LANGUAGE_CODE]: getLocaleAPI().toUpperCase()
                    }
                };
                stepsToSubmit.push(stepData);
                hadToSetLanguage = true;
            }
        } else {
            // no language submitted so far
            stepData = {
                'id': Constants.NETMATCH_STEP_LANGUAGE,
                'content': {
                    [Constants.NETMATCH_INPUT_LANGUAGE_CODE]: getLocaleAPI().toUpperCase()
                }
            };
            stepsToSubmit.push(stepData);
            hadToSetLanguage = true;
        }


        if (urlParams.get(Constants.INITIAL_PARAMETER_TRIPCODE) && urlParams.get(Constants.INITIAL_PARAMETER_EPACKAGECODE)) {
            const INITIAL_TRIPCODE = urlParams.get(Constants.INITIAL_PARAMETER_TRIPCODE).replace(/[^0-9a-zA-Z_-]/g, '');
            const INITIAL_EPACKAGECODE = urlParams.get(Constants.INITIAL_PARAMETER_EPACKAGECODE).replace(/[^0-9a-zA-Z_-]/g, '');
            // TUICUNIT-1625 API tracking
            let clientOption = Constants.NETMATCH_OPTION_CLIENT_WEB;
            if (urlParams.thirdPartyParams && urlParams.thirdPartyParams[Constants.NATIVE_APP_TRIGGER_PARAMETER] && urlParams.thirdPartyParams[Constants.NATIVE_APP_TRIGGER_PARAMETER] === '1') {
                clientOption = Constants.NETMATCH_OPTION_CLIENT_APP;
            }

            stepData = {
                'id': Constants.NETMATCH_STEP_CLIENT,
                'content': {
                    [Constants.NETMATCH_INPUT_CLIENT]: clientOption
                }
            };
            stepsToSubmit.push(stepData);

            stepData = {
                'id': Constants.NETMATCH_STEP_TRIP,
                'content': {
                    [Constants.NETMATCH_INPUT_TRIP_AUFTRAGSNUMMER]: INITIAL_TRIPCODE, [Constants.NETMATCH_INPUT_TRIP_EPACKAGECODE]: INITIAL_EPACKAGECODE
                }
            };

            step = app.apiSession.steps.get(stepData.id);
            if (step && step.validateData(stepData).valid) {
                stepsToSubmit.push(stepData);
            }

            // step 'party'
            if (urlParams.get(Constants.INITIAL_PARAMETER_ADULTS) || urlParams.get(Constants.INITIAL_PARAMETER_CHILDREN)) {
                stepData = {
                    'id': 'party/cabin-1',
                    'content': {
                        [Constants.NETMATCH_INPUT_PARTY_ADULTS]: urlParams.get(Constants.INITIAL_PARAMETER_ADULTS) || '2', [Constants.NETMATCH_INPUT_PARTY_CHILDREN]: urlParams.get(Constants.INITIAL_PARAMETER_CHILDREN) || '0'
                    }
                };
                step = app.apiSession.steps.get(stepData.id);
                if (step && step.validateData(stepData).valid) {
                    stepsToSubmit.push(stepData);
                }
            }

            // step 'cabinCategory'
            if (urlParams.get(Constants.INITIAL_PARAMETER_CABINTYPE)) {
                // yourstruly send GET cabinType we submit this to netmatch cabinCategory TUICIBE-131
                stepData = {
                    'id': 'cabinCategory/cabin-1',
                    'content': {
                        [Constants.NETMATCH_INPUT_CABINCATEGORY_CATEGORYCODE]: urlParams.get(Constants.INITIAL_PARAMETER_CABINTYPE)
                    }
                };
                step = app.apiSession.steps.get(stepData.id);
                stepsToSubmit.push(stepData);
                if (step && step.validateData(stepData).valid) {
                    stepsToSubmit.push(stepData);
                }
            }

            // step 'shipboundairport'
            // INITIAL_PARAMETER_AIRPORT is redundant to support old entry points with only one airport
            if (urlParams.get(Constants.INITIAL_PARAMETER_SHIP_BOUND_AIRPORT) || urlParams.get(Constants.INITIAL_PARAMETER_AIRPORT)) {
                stepData = {
                    'id': Constants.NETMATCH_STEP_SHIPBOUNDTRANSPORTTYPE,
                    'content': {
                        [Constants.NETMATCH_INPUT_TRANSPORTTYPE_TYPE]: Constants.INITIAL_PARAMETER_TRANSPORTTYPE_TYPE
                    }
                };
                step = app.apiSession.steps.get(stepData.id);
                if (step && step.validateData(stepData).valid) {
                    stepsToSubmit.push(stepData);
                }

                stepData = {
                    'id': Constants.NETMATCH_STEP_SHIPBOUNDAIRPORT,
                    'content': {
                        [Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE]: urlParams.get(Constants.INITIAL_PARAMETER_SHIP_BOUND_AIRPORT) ?? urlParams.get(Constants.INITIAL_PARAMETER_AIRPORT)
                    }
                };
                step = app.apiSession.steps.get(stepData.id);
                stepsToSubmit.push(stepData);
                if (step && step.validateData(stepData).valid) {
                    stepsToSubmit.push(stepData);
                }
            }

            // step 'homeboundairport'
            if (urlParams.get(Constants.INITIAL_PARAMETER_HOME_BOUND_AIRPORT)) {
                stepData = {
                    'id': Constants.NETMATCH_STEP_SHIPBOUNDTRANSPORTTYPE,
                    'content': {
                        [Constants.NETMATCH_INPUT_TRANSPORTTYPE_TYPE]: Constants.INITIAL_PARAMETER_TRANSPORTTYPE_TYPE
                    }
                };
                step = app.apiSession.steps.get(stepData.id);
                if (step && step.validateData(stepData).valid) {
                    stepsToSubmit.push(stepData);
                }

                stepData = {
                    'id': Constants.NETMATCH_STEP_HOMEBOUNDAIRPORT,
                    'content': {
                        [Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE]: urlParams.get(Constants.INITIAL_PARAMETER_HOME_BOUND_AIRPORT)
                    }
                };
                step = app.apiSession.steps.get(stepData.id);
                stepsToSubmit.push(stepData);
                if (step && step.validateData(stepData).valid) {
                    stepsToSubmit.push(stepData);
                }
            }

            // IBE-278: a GET Agency number and a Flag but that is B2C
            if (urlParams.get(Constants.INITIAL_PARAMETER_AGENCY_NUMBER)) {
                stepData = {
                    'id': Constants.NETMATCH_STEP_AGENCY,
                    'content': {
                        [Constants.NETMATCH_INPUT_AGENCY_AGENCYNUMBER]: urlParams.get(Constants.INITIAL_PARAMETER_AGENCY_NUMBER), 'IsB2C': true
                    }
                };
                step = app.apiSession.steps.get(stepData.id);
                if (step && step.validateData(stepData).valid) {
                    stepsToSubmit.push(stepData);
                }
            }

            // step 'agency' -> B2B Light
            if (document.cookie && Cookie.getValue(Constants.TUIC_B2B_AGENCY_COOKIE_NAME) && !urlParams.get(Constants.INITIAL_PARAMETER_AGENCY_NUMBER)) {
                stepData = {
                    'id': Constants.NETMATCH_STEP_AGENCY,
                    'content': {
                        [Constants.NETMATCH_INPUT_AGENCY_AGENCYNUMBER]: Cookie.getValue(Constants.TUIC_B2B_AGENCY_COOKIE_NAME)
                    }
                };
                step = app.apiSession.steps.get(stepData.id);
                if (step && step.validateData(stepData).valid) {
                    stepsToSubmit.push(stepData);
                }
            }
        }
        console.log('%c setRequestParameters ', 'background: #222; color: #ffff00', stepsToSubmit);

        if (stepsToSubmit.length === 0) {
            return Promise.resolve();
        }

        // added logic so when client switches language optionsets also get refetched
        return app.apiSession.submitStepsIfYouKnowWhatYouDo(stepsToSubmit, true, hadToSetLanguage);
        // return app.apiSession.submitSteps(stepsToSubmit, true);
    }

    /**
     *
     */
    appBackLink () {
        if (this.ibeStorage && this.ibeStorage.get(Constants.APP_BACK_LINK)) {
            return this.ibeStorage.get(Constants.APP_BACK_LINK);
        }
        return Constants.FALLBACK_BACKLINK;
    }

    /**
     *
     */
    ensureTripCode () {
        return new Promise((resolve, reject) => {
            const tripCode = app.apiSession.bookingStatus.getTripCode();
            if (!tripCode) {
                app.ibeController.ibeStatus.ensureTripCode = false;
                reject(new ApiSessionSetupError('Failed to setup api session: missing tripCode'));
            } else {
                resolve(tripCode);
            }
        });
    }


    /**
     *
     */
    initContentApi () {
        // const initParamas = app.ibeController.ibeStorage.get(Constants.INITIAL_PARAMETER);
        return app.contentApi.init(app.apiSession.bookingStatus.getTripCode());
    }

    setB2BSpecials () {
        if (app.apiSession.bookingStatus.agency.isB2B()) {

            // TUICUNIT-2903
            if (app.ibeController.getB2BinfonetBackURL() && (!app.ibeController.ibeStorage || !app.ibeController.ibeStorage.get(Constants.APP_BACK_LINK))) {
                app.ibeController.ibeStorage.saveAppBackLink(app.ibeController.getB2BinfonetBackURL());
            }

            // no fucking beforeunload Pop-up for b2b
            window.removeEventListener('beforeunload', app.ibeController.onBeforeUnloadFunction);

            if (app.bookingPages.get(Constants.PAGE_PERSONAL)) {
                app.bookingPages.get(Constants.PAGE_PERSONAL).set({
                    'page-title': 'Kundendaten', 'button-back-title': 'zu den Kundendaten', 'button-title': 'Weiter zu den Kundendaten'
                });
            }
            if (app.apiSession.bookingStatus.agency.isOperator()) {
                if (app.bookingPages.get(Constants.PAGE_PAYMENT)) {
                    app.bookingPages.get(Constants.PAGE_PAYMENT).set({
                        'button-title': 'Weiter zur Bezahlung'
                    });
                }
            }

            // remove NETMATCH_STEP_INVOICE if B2B - Agentur
            if (app.apiSession.bookingStatus.agency.isAgentur()) {
                app.bookingPages.get(Constants.PAGE_PERSONAL).set({
                    'api-steps': _.without(app.bookingPages.get(Constants.PAGE_PERSONAL).get('api-steps'), Constants.NETMATCH_STEP_INVOICE)
                });
                app.bookingPages.get(Constants.PAGE_PERSONAL).apiSteps.remove(app.bookingPages.get(Constants.PAGE_PERSONAL).apiSteps.get({
                    id: Constants.NETMATCH_STEP_INVOICE
                }), {
                    silent: true
                });
            }
        }
    }

    /**
     * clear B2B Cookie and LoaclStorage Infomations
     */
    removeB2BCookieAndLocalStorage () {
        this.ibeStorage.deleteStorage();
        Cookie.deleteCookie(Constants.TUIC_B2B_AGENCY_COOKIE_NAME);
    }

    /**
     *
     */
    requestPage (routeKey) {
        console.info('Requesting page:', routeKey);

        // this comes from an restore link and now we find the first open page and jump to her
        if (app.ibeController.ibeStatus.restoreBooking === true) {
            app.ibeController.ibeStatus.restoreBooking = false;
            if (app.bookingPages.getFirstOpenPage()) {
                app.router.redirect(app.bookingPages.getFirstOpenPage().get('route'), true, false);
                // TUICUNIT-3453: edge-case @_@ restore URL is Start URL, its only a test case not a real case
                if (app.bookingPages.getFirstOpenPage().get('route') !== 'cabin') {
                    return true;
                }
            }
        }

        // Hey is this comes from an restore link and the page is correct. Now we need to check if the confirmTracking has been send or not.
        // trigger per mail 05.02.2019 um 14:22 JW
        if (this.ibeInited && app.ibeController.ibeStorage) {
            if (app.ibeController.ibeStorage.get('restoreBookingState') === 'true') {
                if (app.apiSession.steps) {
                    const bookingPaymentent = app.bookingPages.get(Constants.PAGE_PAYMENT);
                    const apiStepModelConfirm = app.apiSession.steps.get(Constants.NETMATCH_STEP_CONFIRM) || null;
                    // if Payment is done
                    if (bookingPaymentent.isDone() && apiStepModelConfirm) {
                        const stepAttributes = _.findWhere(apiStepModelConfirm.get('netMatchAttributes'), {key: Constants.NETMATCH_STEP_CONFIRM_RESULT_CREATE_RESERVATION}) || {};
                        console.log(stepAttributes);
                        if (stepAttributes.status === Constants.NETMATCH_STEP_STATUS_COMPLETED) {
                            app.ibeController.ibeStorage.save(Constants.CONFIRM_STATUS, 'true');
                        }
                    } else {
                        app.ibeController.ibeStorage.save('restoreBookingState', 'false');
                    }
                }
            }
        }

        const routesPastApprovalPage = ['payment', 'confirm'];

        // don't let the user "escape" from pages coming after the approval page
        if (routesPastApprovalPage.indexOf(app.pageController.current) !== -1 && routesPastApprovalPage.indexOf(routeKey) === -1) {
            app.router.redirect(app.pageController.current, false, true);
            return true;
        }

        // TUICIBE-156
        if (this.ibeInited && app.ibeController.ibeStorage) {
            const confirmIsCompleted = app.ibeController.ibeStorage.get(Constants.CONFIRM_STATUS) ? app.ibeController.ibeStorage.get(Constants.CONFIRM_STATUS) : null;
            if (confirmIsCompleted === 'true' && routeKey !== 'confirm') {
                app.router.redirect('confirm', false, true);
                routeKey = 'confirm';
            }
        }

        if (routeKey === 'index') {
            const firstBookingPage = app.bookingPages.first();
            if (!this.ibeInited) {
                if (firstBookingPage) {
                    return this.requestPage(firstBookingPage.get('route'));
                }
            } else {
                routeKey = firstBookingPage.get('route');
            }
        }

        const bookingPageModel = app.bookingPages.get(routeKey);
        console.log(bookingPageModel);
        console.log(app.apiSession.steps);
        let routeIsAccessible = true;
        // route belongs to a booking page
        // only allow access if it is done or open
        if (bookingPageModel) {
            console.info('Requesting booking page:', bookingPageModel.id, ', page status:', bookingPageModel.status);
            // no navigation until ibeInited
            if (!this.ibeInited) {
                console.info('Requesting a booking page but the session is not inited yet. Init session first…');
                this.initIbe();
                return true;
            }

            // if online risk is critical redirect to overview
            const onlineRiskStep = app.apiSession.steps.get(Constants.NETMATCH_STEP_ONLINE_RISK);
            const approvalStep = app.apiSession.steps.get(Constants.NETMATCH_STEP_APPROVAL);
            const paymentStep = app.apiSession.steps.get(Constants.NETMATCH_STEP_PAYMENT);
            const paymentIncomplete = paymentStep ? (paymentStep.isNotApplicable() ? false : !paymentStep.isCompleted()) : false;
            const onlineRiskStepAsserted = onlineRiskStep ? new OnlineRiskStepModel(onlineRiskStep.attributes) : null;

            if (routesPastApprovalPage.indexOf(routeKey) !== -1 && ((onlineRiskStepAsserted && onlineRiskStepAsserted.cannotAdvance()) || (paymentIncomplete))) {
                console.warn('fraud detection not completed, redirect to overview');
                app.router.redirect('overview', true, true);
                return true;
            }


            // do not allow access to pages prior to the approval page, when the approval was already done
            if (approvalStep && approvalStep.isDone() && routesPastApprovalPage.indexOf(routeKey) === -1) {
                if (onlineRiskStepAsserted && onlineRiskStepAsserted.isCompleted()) {
                    const riskAttributes = onlineRiskStepAsserted.getNetmatchAttributes();
                    if (riskAttributes) {
                        const code = riskAttributes.code;
                        switch (code) {
                            case NETMATCH_ONLINE_RISK_NO_RISK:
                            case NETMATCH_ONLINE_RISK_BLACKLISTED_TRAVELER:
                                console.info('redirect to payment');
                                app.router.redirect('payment', true, true);
                                return true;
                            case NETMATCH_ONLINE_RISK_NO_BOOKING_NEGATIVE_CREDIT:
                            case NETMATCH_ONLINE_RISK_NO_BOOKING_BLACKLISTED:
                            case NETMATCH_ONLINE_RISK_NO_BOOKING_UNKNOWN_REASON:
                            case NETMATCH_ONLINE_RISK_NO_DIRECT_DEBIT:
                            case NETMATCH_ONLINE_RISK_UNKNOWN: {
                                if (bookingPageModel.id !== PAGE_OVERVIEW) {
                                    console.info('redirect to overview');
                                    app.router.redirect('overview', true, true);
                                    return true;
                                }
                                break;
                            }
                        }
                    } else {
                        console.info('redirect to payment');
                        app.router.redirect('payment', true, true);
                        return true;
                    }
                }
            }

            if (!(bookingPageModel.isDone() || bookingPageModel.isOpen())) {
                routeIsAccessible = false;
                // TODO remove short circuit
                console.warn('Eigentlich ist diese Seite noch nicht erreichbar.');
                routeIsAccessible = true;
            }
        }

        if (bookingPageModel && bookingPageModel.get('is-not-booking-page') === true) {
            routeIsAccessible = true;
        }

        if (routeIsAccessible) {
            routeIsAccessible = app.pageController.show(routeKey, bookingPageModel);
        }

        if (!routeIsAccessible) {
            if (app.pageController.current) {
                app.router.redirect(app.pageController.current, false, true);
                return true;
            }
            routeIsAccessible = app.pageController.showPrevious();
        }

        if (!routeIsAccessible) {
            // console.log('==============================');
            // console.log(bookingPageModel);
            // console.log(app.bookingPages.getFirstOpenPage().get('route'), app.bookingPages.getFirstOpenPage());
            // console.log(app.router.getCurrentFragment(), app.router);
            // console.log('==============================');

            // find current page position
            if (app.bookingPages.getFirstOpenPage()) {
                if (app.bookingPages.indexOf(bookingPageModel) > app.bookingPages.indexOf(app.bookingPages.getFirstOpenPage())) {
                    routeIsAccessible = app.pageController.show(routeKey, bookingPageModel);
                    app.apiSession.checkStatus();
                    /* const reOpenPageModel = app.bookingPages.getFirstOpenPage();
                    console.log(reOpenPageModel);

                    const stringToDisplay = reOpenPageModel.get('page-title');
                    const savePath = reOpenPageModel.get('route');

                    app.trigger('alert:start', {
                        headline: 'Ihre gewünschte Leistung ist leider nicht mehr Verfügbar.',
                        text: `Bitte aktualisieren Sie Ihre Auswahl. Wir leiten Sie zurück auf den Buchungsschritt: ${stringToDisplay}.`,
                        confirmButtonText: 'Auswahl jetzt aktualisieren',
                        confirmCenter: 'center',
                        closeButtonText: null,
                        closable: false,
                        closeOnBgClick: false,
                        confirmCallback: () => {
                            NavigationUtil.redirect(savePath, true);
                        }
                    }); */
                }
            }
        }

        if (!routeIsAccessible) {
            this.requestPage('error404');
            /* app.trigger('overlay:start', {
                text: 'Die angeforderte Seite konnte nicht angezeigt werden.',
            }); */
        }
        app.trackController.pageView(bookingPageModel);
        return routeIsAccessible;
    }
}

export default IBEController;
