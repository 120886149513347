import {TripInfo} from './interface';
import React, {useRef} from 'react';
import OverlayView from '../overlay';
import app from '../../app/app';
import {FormattedMessage, useIntl} from 'react-intl';
import {formatDate} from '../../intl';

type TripInfoType = {
    tripInfo: TripInfo
    isB2B: boolean,
}

/**
 * View: Ihre Reise
 *
 * @param props TripInfoType
 */
export default function TripInfoView ({tripInfo, isB2B}: TripInfoType) {
    const {formatMessage} = useIntl();

    let webpAddOn = '';
    if (app.canWebp) {
        webpAddOn = '&format=webp';
    }

    const overlayRef = useRef();
    const onOverlay = () => {
        // @ts-ignore
        if (overlayRef.current && overlayRef.current.showModal === false) {
            // @ts-ignore
            overlayRef.current.handleOpenModal();
        }
    };
    const overlayData = {
        tripName: tripInfo.tripName,
        routeMapURL: tripInfo.routeMapURL
    };

    return (
        <section className="check-section">
            <div className="row">
                {!isB2B ?
                    <div className="col-xs-12 col-sm-4 print-tip-image">
                        {tripInfo.routeImage ?
                            <div className="check-image mood">
                                <img alt={formatMessage({
                                    id: 'components.overview.trip-info.headline'
                                })} src={`${tripInfo.routeImage.rawImage}?h=500`} width="500" loading="eager"/>
                            </div>
                            : ''}
                    </div>
                    : ''}
                <div className={`col-xs-7${!isB2B ? ' col-sm-5 print-trip-text' : ' col-sm-9'} text-wrapper`}>
                    <div className="section-headline">
                        <FormattedMessage id="components.overview.trip-info.headline"/>
                    </div>
                    {tripInfo.tripName}<br/>
                    {tripInfo.travelDates ?
                        <>{formatDate(tripInfo.travelDates.from, 'mediumDate')} – {formatDate(tripInfo.travelDates.to, 'mediumDate')}<br/></>
                        : ''}
                    <FormattedMessage id="components.overview.trip-info.nights" values={{
                        amount: tripInfo.numberOfNights
                    }}/>,<span className="ms-italic nobr">{tripInfo.ship}</span><br/>
                </div>
                {tripInfo.routeMapURL ?
                    <div className={`col-xs-5 col-sm-3 align-right${!isB2B ? ' print-route-map' : ''}`} onClick={onOverlay}>
                        <div className={`route-map${isB2B ? ' b2b' : ''}`}>
                            <img alt="Route" className="route-map-image"
                                src={tripInfo.routeMapURL}
                                width="auto"
                                height="auto"/>
                            <a className="enlarge-button"></a>
                        </div>
                    </div>
                    : ''}
                <OverlayView overlayId={'route-map'} closeOnBgClick={true} hasBackButton={true} data={overlayData} ref={overlayRef}/>
            </div>
        </section>
    );
}
