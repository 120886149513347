var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"trip") : depth0),{"name":"with","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":33,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"routeImage") : depth0),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":10,"column":17}}})) != null ? stack1 : "")
    + "\n        <section class=\"booking-status-section\">\n            <div class=\"booking-status-travel-text\">\n                <div class=\"trip-text\">\n                    <div class=\"booking-status-section-headline\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"tripName") || (depth0 != null ? lookupProperty(depth0,"tripName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tripName","hash":{},"data":data,"loc":{"start":{"line":15,"column":65},"end":{"line":15,"column":79}}}) : helper)))
    + " <br>"
    + alias4(((helper = (helper = lookupProperty(helpers,"numberOfNights") || (depth0 != null ? lookupProperty(depth0,"numberOfNights") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"numberOfNights","hash":{},"data":data,"loc":{"start":{"line":15,"column":84},"end":{"line":15,"column":104}}}) : helper)))
    + " "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.nights",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":15,"column":105},"end":{"line":15,"column":143}}})) != null ? stack1 : "")
    + ", <span class=\"ms-italic nobr\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ship") || (depth0 != null ? lookupProperty(depth0,"ship") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ship","hash":{},"data":data,"loc":{"start":{"line":15,"column":174},"end":{"line":15,"column":184}}}) : helper)))
    + "</span></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"travelDates") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":18,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"routeMapURL") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":30,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </section>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"booking-status-image \">\n                <img alt=\"Ihre Reise\" class=\"js-parallax-side-image sidebar-image\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"rawImage") || (depth0 != null ? lookupProperty(depth0,"rawImage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"rawImage","hash":{},"data":data,"loc":{"start":{"line":8,"column":88},"end":{"line":8,"column":102}}}) : helper)))
    + "?h=500\" style=\"object-fit: cover\"/>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div>"
    + alias3((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"travelDates") : depth0)) != null ? lookupProperty(stack1,"from") : stack1),"mediumDate",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":17,"column":29},"end":{"line":17,"column":73}}}))
    + " – "
    + alias3((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"travelDates") : depth0)) != null ? lookupProperty(stack1,"to") : stack1),"mediumDate",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":17,"column":76},"end":{"line":17,"column":118}}}))
    + "</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"align-right\">\n                        <div class=\"route-map js-route-map-overlay\">\n                            <img alt=\"Route\" class=\"route-map-image-booking-status\"\n                                 src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"routeMapURL") || (depth0 != null ? lookupProperty(depth0,"routeMapURL") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"routeMapURL","hash":{},"data":data,"loc":{"start":{"line":24,"column":38},"end":{"line":24,"column":55}}}) : helper)))
    + "\"\n                                 width=\"142\"\n                                 height=\"80\"/>\n                            <a class=\"enlarge-button\"></a>\n                        </div>\n                    </div>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSectionCabin") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":4},"end":{"line":109,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSectionInsurances") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":4},"end":{"line":131,"column":11}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"cabin") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":108,"column":17}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"participantPrices") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":107,"column":19}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <section class=\"booking-status-section\">\n                    <div class=\"cabin-wrapper\">\n                        <div class=\"bold\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.cabin",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":45,"column":42},"end":{"line":45,"column":79}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"cabinIndex") || (depth0 != null ? lookupProperty(depth0,"cabinIndex") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cabinIndex","hash":{},"data":data,"loc":{"start":{"line":45,"column":80},"end":{"line":45,"column":96}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cabinCategoryTypeName") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":97},"end":{"line":45,"column":162}}})) != null ? stack1 : "")
    + "</div>\n                        <div class=\"cabin-arrow\">\n                            <span class=\"total-value nobr\">"
    + alias4((lookupProperty(helpers,"formatPrice")||(depth0 && lookupProperty(depth0,"formatPrice"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"totalPricepPerCabin") : depth0),{"name":"formatPrice","hash":{},"data":data,"loc":{"start":{"line":47,"column":59},"end":{"line":47,"column":94}}}))
    + "</span>\n                            <span class=\"js-cabin-button arrow\" data-cabin="
    + alias4(((helper = (helper = lookupProperty(helpers,"cabinIndex") || (depth0 != null ? lookupProperty(depth0,"cabinIndex") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cabinIndex","hash":{},"data":data,"loc":{"start":{"line":48,"column":75},"end":{"line":48,"column":91}}}) : helper)))
    + "></span>\n                        </div>\n                    </div>\n                    <div class=\"js-cabin-list cabin-list\" data-cabin="
    + alias4(((helper = (helper = lookupProperty(helpers,"cabinIndex") || (depth0 != null ? lookupProperty(depth0,"cabinIndex") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cabinIndex","hash":{},"data":data,"loc":{"start":{"line":51,"column":69},"end":{"line":51,"column":85}}}) : helper)))
    + ">\n                        <div class=\"singel-price\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"participantPrices") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":28},"end":{"line":77,"column":37}}})) != null ? stack1 : "")
    + "                            <div class=\"b-c-part\">\n                                <div>\n                                    <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"priceModel") || (depth0 != null ? lookupProperty(depth0,"priceModel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"priceModel","hash":{},"data":data,"loc":{"start":{"line":83,"column":41},"end":{"line":83,"column":55}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"vip") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":55},"end":{"line":83,"column":83}}})) != null ? stack1 : "")
    + "</div>\n                                    "
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"details") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cabinNumber") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":36},"end":{"line":87,"column":45}}})) != null ? stack1 : "")
    + "</div>\n                            </div>\n                        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"showSectionTransport") : depths[1]),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":24},"end":{"line":104,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </section>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "- "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cabinCategoryTypeName") || (depth0 != null ? lookupProperty(depth0,"cabinCategoryTypeName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cabinCategoryTypeName","hash":{},"data":data,"loc":{"start":{"line":45,"column":128},"end":{"line":45,"column":155}}}) : helper)));
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                                <div class=\"cabin-wrapper price-per-person price-total\">\n                                    <div class=\"label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"count") || (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"count","hash":{},"data":data,"loc":{"start":{"line":59,"column":55},"end":{"line":59,"column":66}}}) : helper)))
    + "x "
    + alias4(((helper = (helper = lookupProperty(helpers,"category") || (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data,"loc":{"start":{"line":59,"column":68},"end":{"line":59,"column":82}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"extraNote") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":83},"end":{"line":59,"column":124}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"discount") : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":40},"end":{"line":62,"column":49}}})) != null ? stack1 : "")
    + "                                    </div>\n                                    <div class=\"value total-value part-price nobr\">"
    + alias4((lookupProperty(helpers,"formatPrice")||(depth0 && lookupProperty(depth0,"formatPrice"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"price") : depth0),{"name":"formatPrice","hash":{},"data":data,"loc":{"start":{"line":64,"column":83},"end":{"line":64,"column":104}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"showSectionCabinEdit") : depths[2]),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":40},"end":{"line":74,"column":49}}})) != null ? stack1 : "")
    + "</div>\n                                </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "("
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"extraNote") || (depth0 != null ? lookupProperty(depth0,"extraNote") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"extraNote","hash":{},"data":data,"loc":{"start":{"line":59,"column":101},"end":{"line":59,"column":116}}}) : helper)))
    + ")";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"discount\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.inclusive",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":61,"column":66},"end":{"line":61,"column":107}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"category") || (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"category","hash":{},"data":data,"loc":{"start":{"line":61,"column":108},"end":{"line":61,"column":122}}}) : helper)))
    + "</div>\n";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.program(23, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":66,"column":44},"end":{"line":72,"column":51}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <a class=\"button-secondary edit-link js-sidebar-tracking\" href=\"cabin#c"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"cabinIndex") : depths[1]), depth0))
    + "\" data-totrack=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"cabinIndex") : depths[1]), depth0))
    + "-kabine-bearbeiten\">\n                                                    <span class=\"icon-ic-edit\"></span>\n                                                </a>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "                                                <span class=\"spacer-status-icon\"></span>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " + "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"vip") || (depth0 != null ? lookupProperty(depth0,"vip") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"vip","hash":{},"data":data,"loc":{"start":{"line":83,"column":69},"end":{"line":83,"column":76}}}) : helper)));
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ", "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.deck",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":86,"column":42},"end":{"line":86,"column":78}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"deckNumber") || (depth0 != null ? lookupProperty(depth0,"deckNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"deckNumber","hash":{},"data":data,"loc":{"start":{"line":86,"column":79},"end":{"line":86,"column":95}}}) : helper)))
    + ", "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.nr",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":86,"column":97},"end":{"line":86,"column":131}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"cabinNumber") || (depth0 != null ? lookupProperty(depth0,"cabinNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cabinNumber","hash":{},"data":data,"loc":{"start":{"line":86,"column":132},"end":{"line":86,"column":149}}}) : helper)));
},"29":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"b-c-part\">\n                                "
    + ((stack1 = container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"section") : depths[1])) != null ? lookupProperty(stack1,"travel") : stack1), depth0)) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"showSectionTransportEdit") : depths[1]),{"name":"if","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":32},"end":{"line":102,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "                                    <a class=\"button-secondary edit-link js-sidebar-tracking\" href=\"travel\" data-totrack=\"anreise-bearbeiten\">\n                                        <span class=\"icon-ic-edit\"></span>\n                                    </a>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"section") : depth0)) != null ? lookupProperty(stack1,"insurances") : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":8},"end":{"line":130,"column":15}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <section class=\"booking-status-section\">\n                <div class=\"b-c-part\">\n                    <span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"section") : depth0)) != null ? lookupProperty(stack1,"insurances") : stack1),{"name":"each","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":119,"column":24},"end":{"line":121,"column":33}}})) != null ? stack1 : "")
    + "                    </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSectionInsurancesEdit") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":20},"end":{"line":127,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </section>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"count") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":33},"end":{"line":120,"column":66}}})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"insurance") || (depth0 != null ? lookupProperty(depth0,"insurance") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"insurance","hash":{},"data":data,"loc":{"start":{"line":120,"column":66},"end":{"line":120,"column":81}}}) : helper)))
    + "</div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"count") || (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"count","hash":{},"data":data,"loc":{"start":{"line":120,"column":46},"end":{"line":120,"column":57}}}) : helper)))
    + "x ";
},"37":function(container,depth0,helpers,partials,data) {
    return "                        <a class=\"button-secondary edit-link js-sidebar-tracking\" href=\"insurance\" data-totrack=\"reiseversicherung-bearbeiten\">\n                            <span class=\"icon-ic-edit\"></span>\n                        </a>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.total",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":141,"column":12},"end":{"line":141,"column":49}}})) != null ? stack1 : "")
    + " <span class=\"price nobr\">"
    + container.escapeExpression((lookupProperty(helpers,"formatPrice")||(depth0 && lookupProperty(depth0,"formatPrice"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"totalBookingPrice") : depth0),{"name":"formatPrice","hash":{},"data":data,"loc":{"start":{"line":141,"column":75},"end":{"line":141,"column":110}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"section") : depth0)) != null ? lookupProperty(stack1,"cancelHint") : stack1)) != null ? lookupProperty(stack1,"show") : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":12},"end":{"line":144,"column":19}}})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"cancel-hint\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"section") : depth0)) != null ? lookupProperty(stack1,"cancelHint") : stack1)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "</div>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"notification\">\n                "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.booking-status.layout.choose",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":147,"column":16},"end":{"line":147,"column":77}}})) != null ? stack1 : "")
    + "\n            </div>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"booking-hint\">\n            <div>\n                <span class=\"bold\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.booking-status.layout.goodToKnow",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":155,"column":35},"end":{"line":155,"column":100}}})) != null ? stack1 : "")
    + "</span> "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.booking-status.layout.goodToKnow.hint",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":155,"column":108},"end":{"line":155,"column":178}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"section") : depth0)) != null ? lookupProperty(stack1,"bookingHint") : stack1)) != null ? lookupProperty(stack1,"isPur") : stack1),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.program(47, data, 0),"data":data,"loc":{"start":{"line":156,"column":16},"end":{"line":164,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"icon-thumb\">\n                <span class=\"icon-thumbs-up\"></span>\n            </div>\n        </div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"general.price-pp",{"name":"formatMessage","hash":{"price":"35%"},"data":data,"loc":{"start":{"line":157,"column":20},"end":{"line":157,"column":72}}})) != null ? stack1 : "")
    + "\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"section") : depth0)) != null ? lookupProperty(stack1,"bookingHint") : stack1)) != null ? lookupProperty(stack1,"isPlus") : stack1),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.program(50, data, 0),"data":data,"loc":{"start":{"line":158,"column":16},"end":{"line":164,"column":16}}})) != null ? stack1 : "");
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"general.price-pp",{"name":"formatMessage","hash":{"price":"30%"},"data":data,"loc":{"start":{"line":159,"column":20},"end":{"line":159,"column":72}}})) != null ? stack1 : "")
    + "\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"section") : depth0)) != null ? lookupProperty(stack1,"bookingHint") : stack1)) != null ? lookupProperty(stack1,"isPro") : stack1),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.program(53, data, 0),"data":data,"loc":{"start":{"line":160,"column":16},"end":{"line":164,"column":16}}})) != null ? stack1 : "");
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"general.price-pp",{"name":"formatMessage","hash":{"price":"25%"},"data":data,"loc":{"start":{"line":161,"column":20},"end":{"line":161,"column":72}}})) != null ? stack1 : "")
    + "\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"general.price-pp-up-to",{"name":"formatMessage","hash":{"price":"35%"},"data":data,"loc":{"start":{"line":163,"column":20},"end":{"line":163,"column":78}}})) != null ? stack1 : "")
    + "\n                ";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"status-print\">\n        <a class=\"print button button-secondary js-sidebar-tracking\" data-totrack=\"angebot-drucken\" href=\"print-offer\">\n            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"general.print-offer",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":202,"column":12},"end":{"line":202,"column":55}}})) != null ? stack1 : "")
    + "\n            <span class=\"icon-print\"></span>\n        </a>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"trip") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":34,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalBookingPrice") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":0},"end":{"line":132,"column":7}}})) != null ? stack1 : "")
    + "\n\n<section class=\"booking-status-price-section\">\n    <div class=\"total-price\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalBookingPrice") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0, blockParams, depths),"inverse":container.program(42, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":140,"column":8},"end":{"line":149,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"section") : depth0)) != null ? lookupProperty(stack1,"bookingHint") : stack1)) != null ? lookupProperty(stack1,"show") : stack1),{"name":"if","hash":{},"fn":container.program(44, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":4},"end":{"line":170,"column":11}}})) != null ? stack1 : "")
    + "    <section class=\"booking-status-section-premium\">\n        <div class=\"premium-wrapper\">\n            <div class=\"premium-headline\"><i class=\"bold\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.mein-schiff",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":176,"column":58},"end":{"line":176,"column":101}}})) != null ? stack1 : "")
    + "</i> <sup>&reg;</sup> "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.booking-status.layout.premium-headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":176,"column":123},"end":{"line":176,"column":194}}})) != null ? stack1 : "")
    + "</div>\n            <span class=\"js-premium-button arrow\"></span>\n        </div>\n        <div class=\"js-premium-list premium-list\">\n            <li><span class=\"icon icon-genuss\"></span><span class=\"text\"><i>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.mein-schiff",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":180,"column":76},"end":{"line":180,"column":119}}})) != null ? stack1 : "")
    + "</i> <sup>&reg;</sup> "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.genussvielfalt",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":180,"column":141},"end":{"line":180,"column":207}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-zeitplan\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.geniessen",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":181,"column":75},"end":{"line":181,"column":136}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-getraenke\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.markengetraenke",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":182,"column":76},"end":{"line":182,"column":143}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-kaffee\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.kaffee",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":183,"column":73},"end":{"line":183,"column":131}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-eis\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.eis",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":184,"column":70},"end":{"line":184,"column":125}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-allergiker\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.allergiker",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":185,"column":77},"end":{"line":185,"column":139}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-espresso\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.espressomaschine",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":186,"column":75},"end":{"line":186,"column":143}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-kabinenservice\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.kabinenservice",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":187,"column":81},"end":{"line":187,"column":147}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-kofferservice\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.kofferservice",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":188,"column":80},"end":{"line":188,"column":145}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-spa\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.spa",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":189,"column":70},"end":{"line":189,"column":125}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-sport\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.sport",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":190,"column":72},"end":{"line":190,"column":129}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-programm\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.programm",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":191,"column":75},"end":{"line":191,"column":135}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-entertainment\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.entertainment",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":192,"column":80},"end":{"line":192,"column":145}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-kinderbetreuung\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.kinderbetreuung",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":193,"column":82},"end":{"line":193,"column":149}}})) != null ? stack1 : "")
    + "</span></li>\n            <li><span class=\"icon icon-trinkgeld\"></span><span class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.overview.premium.trinkgeld",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":194,"column":76},"end":{"line":194,"column":137}}})) != null ? stack1 : "")
    + "</span></li>\n        </div>\n    </section>\n</section>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalBookingPrice") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":0},"end":{"line":206,"column":7}}})) != null ? stack1 : "")
    + "\n";
},"useData":true,"useDepths":true});