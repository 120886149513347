import _ from 'underscore';
import ApiStepBaseView from './base';
import app from '../../../app/app';
import ApiStepFactory from '../../../item/api-step/factory';
import tplWebpackAirportOnly from '../../../../tpl/item/api-step/airport-only.hbs';
import tplWebpackAirport from '../../../../tpl/item/api-step/airport.hbs';
import * as Constants from '../../../config/constants';
import { getIntl } from '../../../intl';


export default class AirportApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        const templateWebpack = (options.isRoundTrip) ? (tplWebpackAirportOnly) : (tplWebpackAirport);
        super({
            templateWebpack,
            ...options
        });
        this.listenTo(this.model.inputs, 'change:inputValue', this.onChangeTracking);
    }


    /**
     * Getter for the views class name
     */
    get className () {
        return `${super.className} api-step-airport`;
    }

    /**
     * View regions.
     * Any region defined under the key of an input id will be filled with the
     * corresponding input view when calling the 'renderApiInputsToRegions' method..
     */
    regions () {
        return {
            [Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE]: {
                el: '.api-input-airportcode',
                replaceElement: false
            },
            'flight': {
                el: '.region-for-api-step-flight',
                replaceElement: false
            }
        };
    }


    /**
     * Any object defined under the key of an input id will be passed as options
     * into the input views created when calling the 'renderApiInputsToRegions' method.
     */
    apiInputViewOptions () {
        const { formatMessage } = getIntl();

        return {
            [Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE]: {
                additionalTemplateContext: {
                    optionGroup: formatMessage({
                        id: 'tpl.item.api-step.view.airport.options'
                    }),
                    nothingSubmittedOption: formatMessage({
                        id: 'tpl.item.api-step.view.airport.nothingToSubmitted'
                    }),
                    label: this.getAirportInputValue()
                }
            }
        };
    }


    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        const templateContext = super.templateContext.apply(this);
        let homeBoundNotDefault = false;

        if (this.options.isRoundTrip) {
            // NETMATCH_STEP_SHIPBOUNDAIRPORT
            if (this.model.get('id') === Constants.NETMATCH_STEP_HOMEBOUNDAIRPORT) {
                if (templateContext.apiStatus === Constants.NETMATCH_STEP_STATUS_OPEN) {
                    const shipBoundStatus = this.model.collection.get(Constants.NETMATCH_STEP_SHIPBOUNDAIRPORT).isCompleted();

                    if (shipBoundStatus) {
                        homeBoundNotDefault = true;
                    }
                }
            }
        }
        return {
            ...templateContext,
            isShipbound: this.isShipbound(),
            label: this.getAirportInputValue(),
            homeBoundNotDefault
        };
    }


    onRender () {
        this.renderApiInputsToRegions(this.model.inputs);
        if (this.model.isSomehowCompleted()) {
            const apiSubStepView = this.getFlightStepView();

            if (apiSubStepView) {
                if (this.options.isRoundTrip) {
                    if (this.isShipbound()) {
                        this.options.mainView.renderSubviewFromChildShipbound({
                            isShipbound: true,
                            view: apiSubStepView
                        });
                        this.model.collection.get(Constants.NETMATCH_STEP_HOMEBOUNDAIRPORT).trigger('change');
                    } else {
                        this.options.mainView.renderSubviewFromChildHomebound({
                            isShipbound: false,
                            view: apiSubStepView
                        });
                    }
                } else {
                    this.showChildView('flight', apiSubStepView);
                }
            // this.showChildView('flight', apiSubStepView);
            }
        }
    }

    isShipbound () {
        return this.model.id.indexOf(Constants.SHIPBOUND) !== -1;
    }

    getAirportInputValue () {
        let label = '';
        if (this.isShipbound()) {
            let airportName = '';
            if (this.model.inputs && this.model.inputs.get(Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE) && this.model.inputs.get(Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE).get('options') && this.model.inputs.get(Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE).get('options')[0]) {
                airportName = this.model.inputs.get(Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE).get('options')[0].arrivalAirport;
            }
            label = getIntl().formatMessage({
                id: 'components.overview.travel.flightOutboundHeadline'
            }, {
                origin: airportName,
                destination: ''
            });
        } else {
            let airportName = '';
            if (this.model.inputs && this.model.inputs.get(Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE) && this.model.inputs.get(Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE).get('options') && this.model.inputs.get(Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE).get('options')[0]) {
                airportName = this.model.inputs.get(Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE).get('options')[0].departureAirport;
            }
            label = getIntl().formatMessage({
                id: 'components.overview.travel.flightReturnHeadline'
            }, {
                origin: airportName,
                destination: ''
            });
        }

        return label;
    }


    getFlightStepView () {
        let nextApiStepId;
        if (this.isShipbound()) {
            nextApiStepId = Constants.NETMATCH_STEP_SHIPBOUNDFLIGHT;
        } else {
            nextApiStepId = Constants.NETMATCH_STEP_HOMEBOUNDFLIGHT;
        }

        const nextApiStepModel = app.apiSession.steps.get(nextApiStepId);
        if (!nextApiStepModel || nextApiStepModel.isPending()) {
            return null;
        }
        nextApiStepModel.reNewInputData();

        return ApiStepFactory.create(nextApiStepModel.id, {
            model: nextApiStepModel,
            autoSubmit: false,
            darkStyling: true,
            additionalTemplateContext: {
                title: getIntl().formatMessage({
                    id: this.isShipbound() ? 'js.item.api-input.view.flight-options.to' : 'js.item.api-input.view.flight-options.back'
                })
            }
        });
    }

    onChangeTracking (inputModelChange) {
        const translate = {
            [Constants.NETMATCH_STEP_SHIPBOUNDAIRPORT]: 'Anreise',
            [Constants.NETMATCH_STEP_HOMEBOUNDAIRPORT]: 'Abreise'
        };
        // console.log(inputModelChange.changed, inputModelChange.changed.defaultValue, inputModelChange.collection.parentModel.status);
        if (_.isUndefined(inputModelChange.changed.defaultValue)) {
            app.trackController.eventTracking({
                action: 'Flug',
                label: translate[inputModelChange.collection.parentModel.id],
                ga4clickLabel: `content.button.flug-${translate[inputModelChange.collection.parentModel.id]}`
            });
        }
    }
}
